/* Inter - Thin (100) */
@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_18pt-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_18pt-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

/* Inter - Extra Light (200) */
@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_18pt-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_18pt-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

/* Inter - Light (300) */
@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_18pt-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_18pt-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

/* Inter - Regular (400) */
@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_18pt-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_18pt-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

/* Inter - Medium (500) */
@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_18pt-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_18pt-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

/* Inter - Semi Bold (600) */
@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_18pt-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_18pt-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

/* Inter - Bold (700) */
@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_18pt-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_18pt-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

/* Inter - Extra Bold (800) */
@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_18pt-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_18pt-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

/* Inter - Black (900) */
@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_18pt-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_18pt-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

iframe {
 display: none !important;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif !important;
  overflow-x: hidden;
  overscroll-behavior-y: contain;  /* disabled pull to refresh in browser */
  /* -webkit-user-select: none;
  -moz-user-select: -moz-none;
   -ms-user-select: none;
       user-select: none; */
}
html{
  overscroll-behavior-y: contain;     /* disabled pull to refresh in browser */
}
/* #root,
#root > div > div {
 height: 100%;
} */
.simple_flex {
 display: flex;
 width: 100%;
}
.simple_flex_no_width {
 display: flex;
}
.width100 {
 width: 100%;
}
.center {
 text-align: center;
}
.display_none {
 display: none;
}
.padding0margin0 {
 margin: 0;
 padding: 0;
}
.padding0 {
 padding: 0 !important;
}
.margin0 {
 margin: 0;
}
.log_out {
 color: #fff;
 cursor: pointer;
}
.chck_bal {
 flex: 1;
 color: #fff;
 cursor: pointer;
}
.display_none {
 display: none;
}
.margin-12 {
 margin-top: -12px;
}
.bold {
 font-weight: bold;
}
.width20 {
 width: 20% !important;
}

.custom-react-datepicker-input {
  height: 40px;
  border: 1px solid var(--clr-white-400) !important;
}

.custom-react-datepicker-input:focus {
 /* border-color: var(--clr-primary-400); */
 box-shadow: 0 0 0 0.2rem var(--taptap-focus-ring-color);
}
.align_center {
 align-self: center;
}
.width100px {
 width: 100px;
}
.margin0auto {
 margin: 0 auto;
}
.flex_grow_1 {
 flex-grow: 1;
}
.flex_grow_05 {
 flex-grow: 0.5;
}
.flex_grow_0 {
 flex-grow: 0 !important;
}
.flex_end {
 align-self: flex-end;
}
.align_start {
 align-self: start;
}
.absolute {
 position: absolute;
}
.relative {
 position: relative;
}
.green {
 color: green;
}
.red {
 color: red;
}
.marginBottom10 {
 margin-bottom: 10px;
}
.marginLeft10 {
 margin-left: 10px;
}
.error_border {
 border-color: red;
}
.stepper-label-jay {
 font-size: 16px;
 font-weight: lighter;
}

@media screen and (max-width: 480px) {
 .stepper-label-jay {
  font-size: 10px;
  font-weight: lighter;
 }
}

.actionbar-jay {
 position: fixed;
 bottom: 0;
 background-color: "white";
 padding: "8px";
 box-shadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)";
}

.url-title-div {
 display: flex;
 justify-content: center;
 align-items: center;
 margin-bottom: 5px;
}
.url-title {
 display: flex;
 justify-content: center;
 align-items: center;
 text-transform: uppercase;
 font-weight: bold;
 color: #000;
 cursor: pointer;
 font-size: 32px;
 background: linear-gradient(to bottom, var(--clr-primary-400) 0%, var(--clr-primary-500) 100%);
 color: #fff;
 border-radius: 10px;
 margin: 0px;
 padding: 10px;
}

.table-pagination {
 display: flex;
 justify-content: center;
 align-items: center;
 margin-top: 10px;
 padding-bottom: 10px;
}
.table-page-container {
 border: 1px solid #3f51b5;
 width: 400px;
 border-radius: 20px;
}

@media screen and (max-width: 770px) {
 .url-title {
  font-size: 20px;
  padding: 5px;
 }
 .table-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding-bottom: 70px;
 }
 .table-page-container {
  border: 1px solid #3f51b5;
  width: 400px;
  border-radius: 20px;
 }
}
.margin-table-header-for-loading {
 margin-left: 50px;
}
@media screen and (max-width: 1730px) {
 .margin-table-header-for-loading {
  margin-left: 35px;
 }
}
@media screen and (max-width: 1590px) {
 .margin-table-header-for-loading {
  margin-left: 25px;
 }
}
@media screen and (max-width: 1430px) {
 .margin-table-header-for-loading {
  margin-left: 15px;
 }
}

.zindex5 {
 z-index: 5;
}

#errormsg {
 visibility: hidden;
 font-weight: 900;
 border-radius: 4px;
 /* min-width: 250px; */
 width: max-content;
 /* margin-left: -125px; */
 background-color: rgb(65, 211, 35);
 color: rgb(0, 0, 0);
 text-align: center;
 /* border-radius: 10px; */
 padding: 16px;
 position: fixed;
 z-index: 10000;
 /* left: 50%;
 bottom: 1rem; */
 /* transform: translateX(-50%); */
 font-size: clamp(1rem, 3vw, 1.75rem);
}

#errormsg.show {
 visibility: visible;
 -webkit-animation: fadein 0.5s;
 animation: fadein 0.5s;
 z-index: 99999;
}

.commonplColor {
 background: linear-gradient(to right, #d82e50 0%, #d82e85 100%);
}

.commonbgColor {
 background: linear-gradient(to right, var(--clr-primary-400) 0%, var(--clr-primary-500) 100%);
}

.commongrColor {
 background: linear-gradient(to right, #8f00ab 0%, #8f00ff 100%);
}

.commonotColor {
 background: linear-gradient(to right, #ff4590 0%, #ff4595 100%);
}

.commonOgColor{
  /* background: linear-gradient(to right, violet 0%, violet 100%); */
  background-color: tomato;
}

.commonhgColor {
 background: linear-gradient(to right, green 10%, green 90%);
}

.bgGreen {
 background-color: #10fd7536;
}

.bgRed {
 background-color: rgb(249 165 165 / 30%);
}
iframe {
 display: none !important;
}

.grecaptcha-badge {
 display: none !important;
}

.MuiTabs-flexContainer {
 justify-content: space-around;
}

/* Creating css for the overlay and loader */
.my-overlay {
 position: fixed;
 inset: 0;
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
 gap: 8px;
 color: whitesmoke;
 background: rgba(0, 0, 0, 0.5);
 backdrop-filter: blur(4px);
 z-index: 100000000;
}

.my-spinner {
 width: 56px;
 height: 56px;
 border-radius: 50%;
 background: radial-gradient(farthest-side, #474bff 94%, #0000) top/9px 9px
   no-repeat,
  conic-gradient(#0000 30%, #474bff);
  mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
 -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
  animation: spinner-c7wet2 1s infinite linear;
}

@keyframes spinner-c7wet2 {
 100% {
  transform: rotate(1turn);
 }
}

button:disabled {
 cursor: not-allowed;
}

.only-checkin-component .carousel-inner {
  height: 100%;
}

.only-checkin-component .item {
  padding: 0 !important;
}

.get-in-btn:focus-within {
  filter: brightness(75%);
}

.focus-within-effect:focus-within {
  filter: brightness(90%) !important;
  outline: none;
}

.focus-within-effect:hover {
  filter: brightness(95%);
  color: unset !important;
}

.btn.btn-lg.btn-submit:hover {
  color: unset !important;
}

.modal-dialog {
  width: calc(100% - calc(var(--base, 16px) * 2)) !important;
  max-width: 800px;
  margin-inline: auto !important;
  margin-block: 0 !important;
  padding: 1rem !important;
}

#peer-review-form-suggestion-textarea::placeholder {
  color: var(--clr-black-100);
}

.fc-daygrid-event-dot {
  display: none;
}

.loading-spinner-animation {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.css-t3ipsp-control {
  box-shadow: 0 0 0 0.2rem var(--taptap-focus-ring-color) !important;
  border-color: var(--clr-white-400) !important;
}

.iOxQSN .resizer {
  right: 4px !important;
}

/* transitions.css */
.transition-page-enter {
  opacity: 0;
  /* transform: translateX(100%); */
}
.transition-page-enter-active {
  opacity: 1;
  /* transform: translateX(0); */
  transition: opacity 0.5s ease;
}

.transition-page-exit {
  opacity: 1;
  /* transform: translateX(0); */
}
.transition-page-exit-active {
  opacity: 0;
  /* transform: translateX(-100%); */
  transition: opacity 0.5s ease;
}

.taptap-stylish-button {
  /* padding: 1rem;
  border-radius: 4px;
  box-shadow: 3px 3px 0 var(--clr-primary-400);
  border: 1px solid var(--clr-primary-400);
  background-color: var(--clr-primary-50); */

  padding: 1rem;
  border-radius: calc(var(--borderRadius) / 4);
  background: var(--clr-white-900);
  border: 1px solid var(--border-light-color);
}

.taptap-textarea-input::placeholder {
  color: var(--clr-black-300);
}

.goog-te-gadget {
  color: transparent !important;
}

.goog-te-gadget > span {
  display: none;
}

.goog-te-gadget select {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid var(--clr-white-400);
  outline: none;
}