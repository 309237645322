.button-danger-primary.hover-effect-on:not(:disabled):hover {
    background-color: var(--clr-danger-500);
}
.button-danger-secondary.hover-effect-on:not(:disabled):hover {
    background-color: var(--clr-danger-100);
    color: var(--clr-white-900);
}

.button-primary-primary.hover-effect-on:not(:disabled):hover {
    background-color: var(--clr-primary-500);
}

.button-primary-secondary.hover-effect-on:not(:disabled):hover {
    background-color: var(--clr-primary-100);
    color: var(--clr-white-900);
}

.taptap-btn:disabled {
    background-color: #d3d3d3; /* Light gray background */
    color: #a9a9a9;           /* Gray text color */
    cursor: not-allowed;      /* Change cursor to indicate it's not clickable */
    opacity: 0.6;             /* Slightly transparent to show it's disabled */
    /* opacity: 0.6; */
    /* pointer-events: none; */
}

.taptap-btn.size-extra-small {
    font-size: 12px !important;
}

.taptap-btn.size-small {
    font-size: 14px !important;
}

.taptap-btn.size-regular {
    font-size: 16px !important;
}

.taptap-btn.size-medium {
    font-size: 18px !important;
}

.taptap-btn.size-large {
    font-size: 20px !important;
}