.taptap-error-boundary {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding-block: 4rem;
  padding-inline: 2rem;
  text-align: center;
  gap: 1rem;
  background-color: var(--clr-white-700);
}

.taptap-error-boundary-content {
  max-width: 500px;
  padding: 20px;
  /* border: 1px solid #f5c6cb; */
  border-radius: 8px;
  /* background-color: #ffffff; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  background-color: var(--clr-white-900);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
}

.taptap-error-boundary-retry-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: var(--clr);
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.taptap-error-boundary-retry-button:hover {
  background-color: #0056b3;
}

.taptap-error-boundary-error-icon {
  font-size: 50px;
  color: var(--clr-danger-400);
}