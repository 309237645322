.my-app-loader {
   display: flex !important;
   align-items: center !important;
   justify-content: center !important;
   flex-direction: column !important;
   overflow: hidden !important;
   min-height: 100svh !important;
   background-color: var(--clr-white-900) !important;
   width: 100% !important;
   margin-inline: auto !important;
   position: fixed;
   inset: 0;
   z-index: 999999999999999999;
   isolation: isolate;
}

.my-app-loader-section svg {
   z-index: 999999999999999999;
   max-width: 400px !important;
}

/* .app-loader-spinner {
   width: 56px !important;
   height: 56px !important;
   border-radius: 50%;
   border: 4px solid;
   border-color: #eee;
   border-right-color: #0071bd;
   animation: spinner-d3wgkg 1s infinite linear;
}

@keyframes spinner-d3wgkg {
   to {
      transform: rotate(1turn);
   }
} */