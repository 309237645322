.fixed {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 30px;
  margin-bottom: 20px;
}

.mobile-page-nav {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
}

.inner_order {
  border: 1px solid var(--clr-black-900);
  border-radius: 10px;
  padding: 6px;
  width: fit-content;
  margin: 3px;
}
.inner_order:hover {
  cursor: pointer;
}
.innerOrderSelected:hover {
  cursor: pointer;
}
.imageForComplaint{
  border: 0.2px solid transparent;
  display: flex;
  margin-top: 5%;
  margin-left: 5%;
  justify-content: left;
}
.imageForComplaint p:last-child{
  border: 0.2px solid transparent;
  margin-left: 5%;
}

.innerOrderSelected {
  color: green;
  border: 1px solid green;
  border-radius: 10px;
  padding: 6px;
  width: fit-content;
  margin: 3px;
}

.photoGallery {
  display: flex;
  align-self: center;
  padding: 6px;
}
.trackingForCustomerContainer{
  border: 0.2px solid transparent;
  width: 95%;
  margin: auto;
  /* margin-top: 1%; */
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.trackingForBranchContainer{
  border: 0.2px solid transparent;
  width: 100%;
  margin: auto;
  margin-top: 1%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.generalInfoForCustomerTracking{
  border: 0.2px solid transparent;
  width: 95%;
  display: block;
  margin: auto;
  font-weight: bold;
  font-size: large;
  text-align: center;
  color: var(--clr-primary-400);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.fromAndToDetails{
  border: 0.2px solid transparent;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left;
  margin: auto;
}
.fromAndToDetails div{
  width: 20%;
  display: flex;
  justify-content: center;
  border: 0.2px solid transparent;
}
.fromAndToDetails div span{
  border: 0.2px solid transparent;
  width: 30%;
  text-align: center;
}
.fromAndToDetails div p{
  border: 0.2px solid transparent;
  /* margin-top: 1%; */
  height: 100%;
  width: 70%;
  text-align: left;
}
.logsContainer{
  border: 0.2px solid transparent;
  display: flex;
  width: 100%;
  margin: auto;
  margin-top: 1%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.singleLog{
  font-weight: bold;
  width: 100%;
  border: 0.2px solid transparent;
}
.detailedLogsBTN{
  margin-top: 0.5%;
  border: 0.2px solid transparent;
  font-size: large;
  background-color: var(--clr-white-900);
  color: rgb(92,14,225);
  cursor: pointer;
}
.detailedLogsBTN button:hover{
  border-color: rgb(92,14,225);
  background-color: rgb(92,14,225);
  color: var(--clr-white-900);
}
.mapContainerForTracking{
  border: 0.2px solid transparent;
  /* display: flex; */
  /* height: 450px; */
  margin: auto;
  padding: 0% 2%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  justify-content: space-between;
}
.mapDiv{
  /* border: 0.2px solid transparent; */
  flex: 3;
  margin-left: 2.5%;
  height: 100%;
}
.complaintContainer{
  margin: auto;
  padding: 0% 2%;
  border: 0.2px solid transparent;
  margin-top: 1%;
  width: 95%;
  display: flex;
  font-weight: bold;
  font-size: large;
  justify-content: space-evenly;
}
.rAndrContainer{
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  padding: 0% 2%;
  width: 95%;
  font-weight: bold;
  font-size: large
}
.chatIconContainer{
  border: 0.2px solid transparent;
  width: 4%;
  cursor: pointer;
  padding: 0.25% 1%; 
  font-size: x-large;
  text-align: center;
  color: rgb(92,14,225);
}
.btnsForComplaints{
  border: 0.2px solid transparent;
  width: 96%;
  display: flex;
  justify-content: space-evenly;
}
.btnsForComplaints button{
  /* width: 15%; */
  font-size: large;
  background-color: var(--clr-primary-50);
  padding: 1rem 1.25rem;
  border: none;
  outline: none;
  border-radius: 10px;
  color: var(--clr-primary-900);
}
.btnsForComplaints button:hover{
  background-color:  var(--clr-primary-400);
  color: var(--clr-white-900);
}
.returnTohomePage{
  width: 7.55%;
  border: 0.2px solid transparent;
  font-size: small;
  border-color: rgb(92,14,225);
  background-color: var(--clr-white-900);
  color: rgb(92,14,225);
}
.returnTohomePage:hover{
  border-color: rgb(92,14,225);
  background-color: rgb(92,14,225);
  color: var(--clr-white-900);
}

.reviewRatingDiv{
  border: 0.2px solid transparent;
  display: flex;
  text-align: center;
}
.reviewRatingDiv .applyReview{
  width: 45%;
  border: 0.2px solid transparent;
  text-align: center;
}
.reviewRatingDiv .applyRating{
  width: 45%;
}
.reviewRatingDiv .submitRating{
  width: 7.5%;
  font-size: x-large;
  margin-left: 2.5%;
  border: 0.2px solid transparent;
}
.reviewRatingDiv .suubmitIcon{
  border: 0.2px solid transparent;
  margin-top: 15%;
  margin-left: 15%;
  background-color: var(--clr-white-900);
  color: rgb(92,14,225);
}
.mapAndLogsContainer{
  border: 0.2px solid transparent;
  width: 95%;
  text-align: center;
  font-weight: bold;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 1%;
  padding: 1%;
}
.generalContainer{
  border: 0.2px solid transparent;
  padding: 1%;
  margin: auto;
  margin-top: 1%;
  width: 95%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.complaintParentContainer{
  border: 0.2px solid transparent;
  padding: 1%;
  margin: auto;
  margin-top: 1%;
  margin-bottom: 2.5%;
  width: 95%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.rAndrParentContainer{
  border: 0.2px solid transparent;
  padding: 1%;
  margin: auto;
  margin-bottom: 2.5%;
  margin-top: 1%;
  width: 95%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.headingTracking{
  border: 0.2px solid transparent;
  display: flex;
  width: 100%;
}
.headingBranchTracking{
  border: 0.2px solid transparent;
  display: flex;
  width: 90%;
  margin: auto;
  margin-top: 1%;
}
.doorDeliveryPopup{
  border: 0.2px solid transparent;
  margin: auto;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
  width: 15%;
}
.actualTitle{
  border: 0.2px solid transparent;
  font-size: x-large;
  background-color: var(--clr-primary-400);
  color: var(--clr-white-900);
  margin-left: 45%;
  width: 20%;
  text-align: center;
  padding: 0.75% 2%;
}
.actualTitleCenter{
  border: 0.2px solid transparent;
  font-size: x-large;
  background-color: var(--clr-primary-400);
  color: var(--clr-white-900);
  margin-left: 30%;
  width: 20%;
  text-align: center;
  padding: 0.75% 2%;
}
.headingBranchTracking .optionButtonsDiv{
  border: 0.2px solid transparent;
  font-size: large;
  text-align: center;
  display: flex;
  width: 25%;
  margin-right: 2.5%;
  margin-left: auto;
  justify-content: space-around;
}
.headingBranchTracking .optionButtonsDiv button{
  width: 30%;
  background-color: var(--clr-white-900);
  color: rgb(92,14,225);
  border: 0.2px solid transparent;
}
.optionButtonsDiv{
  border: 0.2px solid transparent;
  font-size: large;
  text-align: center;
  display: flex;
  width: 15%;
  margin-right: 2.5%;
  margin-left: auto;
  justify-content: space-around;
}
.optionButtonsDiv button{
  width: 30%;
  background-color: var(--clr-white-900);
  color: rgb(92,14,225);
  border: 0.2px solid transparent;
}
.PopupDialogComlaint{
  border: 0.2px solid transparent;
  width: 500px;
  display: flex;
}
.PopupDialogComlaint4{
  border: 0.2px solid transparent;
  width: 400px;
  display: block;
}
.descriptionInputCompaint{
  border: 0.32px solid transparent;
  width: 60%;
}
.refrenceImageComplaint{
  border: 0.32px solid transparent;
  width: 40%;
  font-size: x-large;
}
.mt5{
  margin-top: 5%;
}
.refrenceImageComplaint4{
  border: 0.32px solid transparent;
  width: 100%;
  font-size: x-large;
}
.ownLogContainer{
  border: 0.2px solid transparent;
  font-size: large;
  display: flex;
  justify-content: space-evenly;
  width: 95%;
  margin: auto;
  margin-top: 1%;
}
.ownLogBranchContainer{
  border: 0.2px solid transparent;
  font-size: large;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin: auto;
  margin-top: 1%;
}
.lastActivityTitle{
  border: 0.2px solid transparent;
  padding: 0.25% 0.75%;
  text-align: center;
  background-color: var(--clr-primary-400);
  width: 14%;
  color: var(--clr-white-900);
}
.lastActivityDate{
  width: 14%;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  border: 0.2px solid transparent;
}
.lastActivityTime{
  width: 12%;
  text-align: center;
  display: flex;
  /* justify-content: space-evenly; */
  border: 0.2px solid transparent;
}
.lastActivityStatus{
  width: 18%;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  border: 0.2px solid transparent;
}
.lastActivityUser{
  width: 15%;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  border: 0.2px solid transparent;
}
.lastActivityCall{
  width: 20%;
  text-align: center;
  display: flex;
  /* justify-content: space-evenly; */
  border: 0.2px solid transparent;
}
.lastActivityImage{
  width: 3.5%;
  text-align: center;
  display: flex;
  justify-content: center;
  border: 0.2px solid transparent;
}
.lastActivityBTN{
  margin-top: 0.2%;
  border: 0.2px solid transparent;
  width: 5%;
  text-align: center;
  font-size: large;
  background-color: var(--clr-white-900);
  color: rgb(92,14,225);
  cursor: pointer;
}
.lastActivityBTN button:hover{
  border-color: rgb(92,14,225);
  background-color: rgb(92,14,225);
  color: var(--clr-white-900);
}
.PopupPackageDataContainer{
  border: 0.2px solid transparent;
  width: 400px;
  height: 200px;
  font-size: medium;
  font-weight: bold;
}
.PopupPackageDataContainer div{
  display: flex;
  width: 100%;
}
.PopupPackageDataContainer div p:last-child{
  border: 0.2px solid transparent;
  text-align: left;
  width: 40%;
}
.PopupPackageDataContainer div p:first-child{
  border: 0.2px solid transparent;
  text-align: left;
  width: 60%;
}
.fromAndToBranchDetails{
  border: 0.2px solid transparent;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left;
  margin: auto;
}
.fromAndToBranchDetails div{
  width: 25%;
  display: flex;
  margin-top: 0.5%;
  justify-content: left;
  border: 0.2px solid transparent;
}
.fromAndToBranchDetails div span{
  border: 0.2px solid transparent;
  width: 12.5%;
  text-align: center;
}
.fromAndToBranchDetails div p{
  border: 0.2px solid transparent;
  width: 87.5%;
  height: 90%;
  margin-top: 1%;
  text-align: left;
}
.generalInfoForBranchTracking{
  border: 0.2px solid transparent;
  width: 98%;
  display: block;
  margin: auto;
  font-size: large;
  text-align: center;
  font-weight: bold;
  color: var(--clr-primary-400);
}
.complaintParentContainer .lastComplaintArr{
  border: 0.2px solid transparent;
  height: 55px;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
}
.complaintParentContainer .eachcomplaintContainer{
  margin: auto;
  text-align: left;
  margin-top: 1%;
  margin-bottom: 1%;
  width: 100%;
  height: 100%;
  border: 0.2px solid transparent;
  font-size: large;
  font-weight: bold;
}
.complaintParentContainer .eachcomplaintContainer .middleDiv{
  width: 95%;
  display: flex;
  height: 100%;
  justify-content: space-evenly;
  border: 0.2px solid transparent;
}
.complaintParentContainer .complaintTypeText{
  border: 0.2px solid transparent;
  width: 27.5%;
  display: flex;
  justify-content: center;
  overflow-y: hidden;
}
.complaintParentContainer .comaplintTime{
  border: 0.2px solid transparent;
  text-align: center;
  width: 15%;
  display: flex;
  justify-content: space-evenly; 
}
.complaintParentContainer .textOverFlowed{
  border: 0.2px solid transparent;
  width: 27.5%;
  height: 80%;
  display: flex;
  text-overflow: hidden;
  overflow-y: hidden;
  justify-content: left;
}
.complaintParentContainer .complaintNo{
  border: 0.2px solid transparent;
  text-align: center;
  width: 15%;
  display: flex;
  justify-content: space-evenly;
}
.complaintParentContainer .lastComplaint{
  border: 0.2px solid var(--clr-black-900);
  width: 2.5%;
  padding: 0.25% 0%;
  background-color: var(--clr-primary-400);
  color: var(--clr-white-900);
  text-align: center;
}
.complaintParentContainer .complaintStatus{
  border: 0.2px solid transparent;
  text-align: center;
  width: 15%;
  display: flex;
  justify-content: space-evenly;
}
.complaintParentContainer .goToChat{
  width: 7.5%;
  height: 80%;
  font-size: large;
  text-align: center;
  background-color: var(--clr-white-900);
  color: rgb(92,14,225);
  display: flex;
  justify-content: space-evenly;
  border: 0.2px solid transparent;
  cursor: pointer;
}
.responseContainer{
  border: 0.23px solid transparent;
  width: 95%;
  height: fit-content;
  margin: auto;
  margin-top: 1.5%;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}
.responseheadContainer{
  border: 0.23px solid transparent;
  width: 97.5%;
  padding: 0.75%;
  height: fit-content;
  margin: auto;
}
.CustomerBranchBookingTrackingContainer{
  border: 0.2px solid transparent;
  width: 95%;
  margin: auto;
  padding: 1% 0%;
  margin-top: 1%;
  margin-bottom: 1%;
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}
.customisedResponse{
  border: 0.2px solid transparent;
  width: 95%;
  height: fit-content;
  margin: auto;
  margin-top: 1%;
  padding: 0.75%;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
}
.mainContainerForBranchTrack{
  border: 0.2px solid transparent;
  width: 97.5%;
  height: 100%;
  margin: auto;
  display: flex;
}
.singleStatusForBranchTracking{
  border: 0.2px solid transparent;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-evenly;
}
.singleStatusForBranchTracking .noOfPackagesForBookingTrack{
  border: 0.2px solid transparent;
  width: 2.5%;
  text-align: center;
  font-size: large;
  display: flex;
  cursor: pointer;
  line-height: 45px;
  justify-content: space-evenly;
  font-weight: large;
}
.singleStatusForBranchTracking .materialTypeAndPackageType{
  border: 0.2px solid transparent;
  width: 50%;
  text-align: center;
  font-size: large;
  font-weight: large;
  line-height: 45px;
}
.singleStatusForBranchTracking .StatusConatinerForBookingTracking{
  border: 0.2px solid transparent;
  width: 25%;
  text-align: center;
  font-size: large;
  line-height: 45px;
  display: flex;
  justify-content: center;
}
.packageBtnsForComplaints{
  border: 0.2px solid transparent;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1%;
  gap: 2%;
}
.eachStatusContainer{
  border: 0.2px solid transparent;
  width: 100%;
  padding: 0.5%;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px;
  display: block;
}
.eachStatusContainerForComplaint{
  border: 0.2px solid transparent;
  width: 1000px;
  padding: 0.5%;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px;
  display: block;
}
.progressBarDiv{
  border: 0.2px solid transparent;
  width: 100%;
  /* height: 50px; */
  text-align: center;
  /* display: block; */
  font-size: large;
  /* justify-content: space-evenly; */
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px;
}
.lastResultName{
  border: 0.2px solid transparent;
  width: 30%;
  font-size: large;
  text-align: center;
}
.singleStatusForBranchTracking .ContainerForMaterial{
  border: 0.2px solid transparent;
  display: flex;
  text-align: center;
}
.singleStatusForBranchTracking .ContainerForMaterial div{
  border: 0.2px solid transparent;
  width: 100%;
  display: flex;
  justify-content: center;
}
.singleStatusForBranchTracking .ContainerForMaterial span{
  border: 0.2px solid transparent;
}
.activateGreen{
  color: var(--clr-white-900);
  background-color: green;
}
.normalStatus{
  color: var(--clr-black-900);
  background-color: var(--clr-white-900);
}
.pkgDialogueContent{
  border: 0.2px solid transparent;
  text-align: center;
  font-size: large;
  font-weight: bold;
  gap: 5%;
}
.pkgDialogueContent .listOfPackages{
  border: 0.2px solid transparent;
  padding: 2.5%;
  display: flex;
  flex-wrap: wrap;
  width: 400px;
  height: fit-content;
}
.pkgDialogueContent .listOfPackages div{
  border: 0.2px solid var(--clr-primary-400);
  margin-top: 0.5%;
  margin-left: 0.5%;
  width: 49%;
}  
.pkgDialogueContentForComplaint{
  border: 0.2px solid transparent;
  text-align: center;
  width: 100%;
  font-size: large;
  font-weight: bold;
  gap: 5%;
}
.pkgDialogueContentForComplaint .listOfPackages{
  border: 0.2px solid transparent;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content;
}
.pkgDialogueContentForComplaint .listOfPackages button{
  border: 0.2px solid var(--clr-black-900);
  margin-top: 1%;
  margin-left: 1%;
  width: 15.5%;
}  
.complaintBtn{
  border: 0.2px solid rgb(92,14,225);
  border-radius: 10%;
  background-color: #fff;
  color: rgb(92,14,225);
}
.complaintBtn:hover{
  border: 0.2px solid rgb(92,14,225);
  border-radius: 10%;
  background-color: rgb(92,14,225);
  color: #fff;
}
.singleProgressBar{
  border: none;
  height: 60%;
}
.singleStepz{
  border: 00.2px solid transparent;
  /* margin-top: -2.5%; */
}

@media (max-width: 844px) {
  .trackingForCustomerContainer{
    border: 0.2px solid transparent;
    width: 100%;
    margin: auto;
    /* margin-top: 1%; */
  }
  .headingTracking{
    border: 0.2px solid transparent;
    display: block;
  }  
  .actualTitle{
    border: 0.2px solid transparent;
    width: 100%;
    margin-left: 0%;
    margin-top: 2.5%;
  }
  .optionButtonsDiv{
    border: 0.2px solid transparent;
    width: 75%;
    margin-inline: auto;
    /* margin-left: 12.5%;
    margin-right: 2.5%;
    margin-top: 2.5%; */
  }
  .generalContainer{
    border: 0.2px solid transparent;
    margin-top: 3.5%;
  }
  .generalInfoForCustomerTracking{
    border: 0.2px solid transparent;  
    display: flex;
  }
  .fromAndToDetails{
    border: 0.2px solid transparent;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: left;
    margin: auto;
  }
  .fromAndToDetails .twoTableContainers{
    border: 0.2px solid transparent;
    width: 50%;
    display: block;
  }
  .twoTableContainers div{
    border: 0.2px solid transparent;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .twoTableContainers div span{
    border: 0.2px solid transparent;
    width: 30%;
    text-align: center;
  }
  .twoTableContainers div p{
    border: 0.2px solid transparent;
    /* margin-top: 1%; */
    height: 100%;
    width: 70%;
    text-align: left;
  }
  .mapAndLogsContainer{
    border: 0.2px solid transparent;
    width: 95%;
    margin: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-top: 3.5%;
    padding: 1%;
  }
  .mapContainerForTracking{
    border: 0.2px solid transparent;
    display: block;
    height: 500px;
    width: 95%;
    margin: auto;
    padding: 0% 2%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
  .mapDiv{
    /* border: 0.2px solid transparent; */
    flex: 3;
    height: 100%;
  }
  .logsContainer{
    border: 0.2px solid transparent;
    display: block;
    width: 100%;
    margin: auto;
    margin-top: 2.5%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
  .singleLog{
    font-weight: bold;
    width: 100%;
    padding: 0% 2.5%;
  }
  .ownLogContainer{
    border: 0.2px solid transparent;
    font-size: large;
    display: flex;
    width: 100%;
    margin-top: 2.5%;
    padding: 0% 1%;
    justify-content: space-evenly;
  }
  .firstContainer{
    border: 0.2px solid transparent;
    font-size: large;
    display: block;
    width: 50%;
    margin-top: 2.5%;
    padding: 0% 1%;
    justify-content: space-evenly;
  }
  .secondContainer{
    border: 0.2px solid transparent;
    font-size: large;
    display: block;
    width: 50%;
    margin-top: 2.5%;
    padding: 0% 1%;
    justify-content: space-evenly;
  }
  .detailedLogsBTN{
    margin-top: 0.5%;
    border: 0.2px solid transparent;
    font-size: large;
    text-align: center;
    background-color: var(--clr-white-900);
    color: rgb(92,14,225);
    margin-top: 2.5%;
    cursor: pointer;
  }
  .detailedLogsBTN button:hover{
    border-color: rgb(92,14,225);
    background-color: rgb(92,14,225);
    color: var(--clr-white-900);
  }
  .rAndrParentContainer{
    border: 0.2px solid transparent;
    padding: 1%;
    margin: auto;
    margin-bottom: 2.5%;
    margin-top: 3.5%;
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .complaintParentContainer{
    border: 0.2px solid transparent;
    padding: 1%;
    margin: auto;
    display: block;
    margin-top: 3.5%;
    margin-bottom: 15%;
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .complaintContainer{
    margin: auto;
    padding: 0% 2%;
    width: 95%;
    height: 50%;
    margin-bottom: 10%;
    font-weight: bold;
    font-size: large;
    display: block;
    border: 0.2px solid transparent;
  }
  .complaintParentContainer .lastComplaintArr{
    border: 0.2px solid transparent;
    height: fit-content;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    margin-bottom: 5%;
  }
  .complaintParentContainer .eachcomplaintContainer .lastComplaint{
    border: 0.2px solid transparent;
    width: 100%;
  }
  .complaintParentContainer .eachcomplaintContainer .middleDiv{
    width: 95%;
    display: block;
    height: 100%;
    justify-content: space-evenly;
    border: 0.2px solid transparent;
  }
  .complaintParentContainer .eachcomplaintContainer .middleDiv .customerMobileFirstDiv{
    border: 0.2px solid transparent;
    width: 100%;
  }
  .complaintParentContainer .eachcomplaintContainer .middleDiv .customerMobileFirstDiv .dateAndTimeMobileComplaint{
    border: 0.2px solid transparent;
    width: 100%;
    display: flex;
  }
  .complaintParentContainer .comaplintTime{
    border: 0.2px solid transparent;
    text-align: center;
    width: 50%;
    display: flex;
    justify-content: space-evenly; 
  }
  .complaintParentContainer .complaintNo{
    border: 0.2px solid transparent;
    text-align: center;
    width: 50%;
    display: flex;
    justify-content: space-evenly;
  }
  .complaintParentContainer .complaintStatus{
    border: 0.2px solid transparent;
    text-align: center;
    width: 50%;
    margin: auto;
    display: flex;
    padding: 1%;
    justify-content: space-evenly;
  }
  .complaintParentContainer .complaintTypeText{
    border: 0.2px solid transparent;
    width: 100%;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    display: flex;
    justify-content: center;
    overflow-y: hidden;
  }
  .complaintParentContainer .textOverFlowed{
    border: 0.2px solid transparent;
    width: 87.5%;
    height: fit-content;
    margin: auto;
    display: flex;
    text-overflow: hidden;
    overflow-y: hidden;
    padding: 1%;
    justify-content: left;
  }
  .complaintParentContainer .goToChat{
    width: 12.5%;
    font-size: large;
    text-align: center;
    background-color: var(--clr-white-900);
    color: rgb(92,14,225);
    border: 0.2px solid transparent;
    cursor: pointer;
  }
  
  .complaintParentContainer .eachcomplaintContainer .middleDiv .customerMobileSecondDiv{
    border: 0.2px solid transparent;
    width: 100%;
    display: flex;
  }
  .btnsForComplaints{
    border: 0.2px solid transparent;
    width: 100%;
    display: block;
  }
  .btnsForComplaints button{
    width: 100%;
    margin-top: 2.5%;
    font-size: large;
    border-color: rgb(92,14,225);
    background-color: var(--clr-white-900);
    color: rgb(92,14,225);
  }
  .btnsForComplaints button:hover{
    border-color: rgb(92,14,225);
    background-color: rgb(92,14,225);
    color: var(--clr-white-900);
  }
  .PopupDialogComlaint{
    border: 0.2px solid transparent;
    width: 250px;
    display: block;
  }
  .descriptionInputCompaint{
    border: 0.32px solid transparent;
    width: 100%;
  }
  .refrenceImageComplaint{
    border: 0.32px solid transparent;
    width: 100%;
    text-align: center;
    font-size: x-large;
  }
}

