/* System Utility CSS */

.top-0-important {
  top: 0px !important;
}

/* root variables */
:root {
  --base: 16px;
  --gap: var(--base);
  --padding: var(--base);
  --margin: var(--base);
  --borderRadius: 20px;
  --zIndex: 1;
  --borderWidth: 1px;
  --borderType: solid;
  --fontSize: calc(var(--base));
  --taptap-focus-ring-color: rgba(0, 123, 255, .25);
  --border-light-color: #ebebeb;
  --bg-white-light: hsl(220, 60%, 97%);
  --fs-c: 16px;
}

/* Flex */
.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.flex-evenly {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.flex-center {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.noCopy {
  user-select: none; /* Prevent text selection */
  -webkit-user-select: none; /* For Safari */
  -ms-user-select: none; /* For Internet Explorer */
}


.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-1 {
  flex: 1;
}

.ai-stretch {
  align-items: stretch;
}
.ai-center {
  align-items: center;
}

.ai-baseline {
  align-items: baseline;
}

.ai-start {
  align-items: flex-start;
}

.ai-end {
  align-items: flex-end;
}

.jc-center {
  justify-content: center;
}

.jc-between {
  justify-content: space-between;
}

.jc-start {
  justify-content: flex-start;
}

.jc-end {
  justify-content: flex-end;
}

.jc-stretch {
  justify-content: stretch;
}

.jc-evenly {
  justify-content: space-evenly;
}

.jc-around {
  justify-content: space-around;
}

.as-start {
  align-self: flex-start;
}

.as-end {
  align-self: flex-end;
}

.as-center {
  align-self: center;
}

.as-baseline {
  align-self: baseline;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-grow-1 {
  flex-grow: 1;
}

/* Grid */
.grid {
  display: grid;
}

.grid-center {
  display: grid;
  place-items: center;
}

.one-column,
.grid-1-column,
.grid-one-column {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.two-column,
.grid-two-column,
.grid-2-column {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.three-column,
.grid-three-column,
.grid-3-column {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.four-column,
.grid-four-column,
.grid-4-column {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.five-column,
.grid-five-column,
.grid-5-column {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.six-column,
.grid-six-column,
.grid-6-column {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.seven-column,
.grid-7-column,
.grid-seven-column {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.eight-column,
.grid-8-column,
.grid-eight-column {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}

.nine-column,
.grid-9-column,
.grid-nine-column {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
}

.ten-column,
.grid-10-column,
.grid-ten-column {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}

.eleven-column,
.grid-11-column,
.grid-eleven-column {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
}

.twelve-column,
.grid-12-column,
.grid-twelve-column {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.responsive-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 1fr));
}

.pi-center {
  place-items: center;
}

.ac-center {
  align-content: center;
}

/* Padding */
.p-3 {
  padding: calc(var(--padding) * 6) calc(var(--padding) * 6);
}

.p-1 {
  padding: var(--padding);
}

.p-2 {
  padding: calc(var(--padding) * 2);
}

.p-4 {
  padding: calc(var(--padding) / 4);
}

.p-8 {
  padding: calc(var(--padding) / 2);
}

.p-10 {
  padding: calc(var(--padding) - 6px);
}

.p-20 {
  padding: calc(var(--padding) + 4px);
}

.p-0 {
  padding: 0;
}

.px-1 {
  padding-inline: var(--padding);
}

.px-2 {
  padding-inline: calc(var(--padding) * 2);
}

.px-4 {
  padding-inline: calc(var(--padding) / 4);
}

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.px-8 {
  padding-inline: calc(var(--padding) / 2);
}

.px-10 {
  padding-inline: calc(var(--padding) - 6px);
}

.px-20 {
  padding-inline: calc(var(--padding) + 4px);
}

.px-0 {
  padding-inline: 0;
}

.py-1 {
  padding-block: var(--padding);
}

.py-2 {
  padding-block: calc(var(--padding) * 2);
}

.py-4 {
  padding-block: calc(var(--padding) / 4);
}

.py-8 {
  padding-block: calc(var(--padding) / 2);
}

.py-10 {
  padding-block: calc(var(--padding) - 6px);
}

.py-20 {
  padding-block: calc(var(--padding) + 4px);
}

.py-0 {
  padding-block: 0;
}

.pl-1 {
  padding-left: var(--padding);
}

.pl-2 {
  padding-left: calc(var(--padding) * 2);
}

.pl-4 {
  padding-left: calc(var(--padding) / 4);
}

.pl-8 {
  padding-left: calc(var(--padding) / 2);
}

.pl-10 {
  padding-left: calc(var(--padding) - 6px);
}

.pl-20 {
  padding-left: calc(var(--padding) + 4px);
}

.pl-0 {
  padding-left: 0;
}

.pr-1 {
  padding-right: var(--padding);
}

.pr-2 {
  padding-right: calc(var(--padding) * 2);
}

.pr-4 {
  padding-right: calc(var(--padding) / 4);
}

.pr-8 {
  padding-right: calc(var(--padding) / 2);
}

.pr-10 {
  padding-right: calc(var(--padding) - 6px);
}

.pr-20 {
  padding-right: calc(var(--padding) + 4px);
}

.pr-0 {
  padding-right: 0;
}

.pt-1 {
  padding-top: var(--padding);
}

.pt-2 {
  padding-top: calc(var(--padding) * 2);
}

.pt-4 {
  padding-top: calc(var(--padding) / 4);
}

.pt-8 {
  padding-top: calc(var(--padding) / 2);
}

.pt-10 {
  padding-top: calc(var(--padding) - 6px);
}

.pt-20 {
  padding-top: calc(var(--padding) + 4px);
}

.pt-0 {
  padding-top: 0;
}

.pb-1 {
  padding-bottom: var(--padding);
}

.pb-2 {
  padding-bottom: calc(var(--padding) * 2);
}

.pb-4 {
  padding-bottom: calc(var(--padding) / 4);
}

.pb-8 {
  padding-bottom: calc(var(--padding) / 2);
}

.pb-10 {
  padding-bottom: calc(var(--padding) - 6px);
}

.pb-20 {
  padding-bottom: calc(var(--padding) + 4px);
}

.pb-0 {
  padding-bottom: 0;
}

/* Margin */
.m-0 {
  margin: 0;
}

.m-1 {
  margin: var(--margin);
}

.m-2 {
  margin: calc(var(--margin) * 2);
}

.m-4 {
  margin: calc(var(--margin) / 4);
}

.m-8 {
  margin: calc(var(--margin) / 2);
}

.m-10 {
  margin: calc(var(--margin) - 6px);
}

.m-20 {
  margin: calc(var(--margin) + 4px);
}

.mx-1 {
  margin-inline: var(--margin);
}

.mx-2 {
  margin-inline: calc(var(--margin) * 2);
}

.mx-4 {
  margin-inline: calc(var(--margin) / 4);
}

.mx-8 {
  margin-inline: calc(var(--margin) / 2);
}

.mx-10 {
  margin-inline: calc(var(--margin) - 6px);
}

.mx-20 {
  margin-inline: calc(var(--margin) + 4px);
}

.mx-0 {
  margin-inline: 0;
}

.mx-auto {
  margin-inline: auto;
}

.ml-auto {
  margin-left: auto;
}

.my-1 {
  margin-block: var(--margin);
}

.my-2 {
  margin-block: calc(var(--margin) * 2);
}

.my-4 {
  margin-block: calc(var(--margin) / 4);
}

.my-8 {
  margin-block: calc(var(--margin) / 2);
}

.my-10 {
  margin-block: calc(var(--margin) - 6px);
}

.my-20 {
  margin-block: calc(var(--margin) + 4px);
}

.my-0 {
  margin-block: 0;
}

.my-auto {
  margin-block: auto;
}

.ml-1 {
  margin-left: var(--margin);
}

.ml-2 {
  margin-left: calc(var(--margin) * 2);
}

.ml-4 {
  margin-left: calc(var(--margin) / 4);
}

.ml-8 {
  margin-left: calc(var(--margin) / 2);
}

.ml-10 {
  margin-left: calc(var(--margin) - 6px);
}

.ml-20 {
  margin-left: calc(var(--margin) + 4px);
}

.ml-0 {
  margin-left: 0;
}

.mr-1 {
  margin-right: var(--margin);
}

.mr-2 {
  margin-right: calc(var(--margin) * 2);
}

.mr-4 {
  margin-right: calc(var(--margin) / 4);
}

.mr-8 {
  margin-right: calc(var(--margin) / 2);
}

.mr-10 {
  margin-right: calc(var(--margin) - 6px);
}

.mr-20 {
  margin-right: calc(var(--margin) + 4px);
}

.mr-0 {
  margin-right: 0;
}

.mr-auto {
  margin-right: auto;
}

.mt-1 {
  margin-top: var(--margin);
}

.mt-2 {
  margin-top: calc(var(--margin) * 2);
}

.mt-4 {
  margin-top: calc(var(--margin) / 4);
}

.mt-8 {
  margin-top: calc(var(--margin) / 2);
}

.mt-10 {
  margin-top: calc(var(--margin) - 6px);
}

.mt-20 {
  margin-top: calc(var(--margin) + 4px);
}
.mt-0 {
  margin-top: 0;
}

.mt-auto {
  margin-top: auto;
}

.mb-1 {
  margin-bottom: var(--margin);
}

.mb-2 {
  margin-bottom: calc(var(--margin) * 2);
}

.mb-4 {
  margin-bottom: calc(var(--margin) / 4);
}

.mb-8 {
  margin-bottom: calc(var(--margin) / 2);
}

.mb-10 {
  margin-bottom: calc(var(--margin) - 6px);
}

.mb-20 {
  margin-bottom: calc(var(--margin) + 4px);
}

.mb-0 {
  margin-bottom: 0;
}

.mb-auto {
  margin-bottom: auto;
}

/* Gap */
.gap-1 {
  gap: var(--gap);
}

.gap-2 {
  gap: calc(var(--gap) * 2);
}

.gap-component {
  gap: calc(var(--gap) * 4);
}

.gap-3 {
  gap: calc(var(--gap) * 5);
}

.gap-4 {
  gap: calc(var(--gap) / 4);
}

.gap-8 {
  gap: calc(var(--gap) / 2);
}

.gap-10 {
  gap: calc(var(--gap) - 6px);
}

.gap-20 {
  gap: calc(var(--gap) + 4px);
}

.gap-0 {
  gap: 0;
}

.gap-x-1 {
  row-gap: var(--gap);
}

.gap-x-2 {
  row-gap: calc(var(--gap) * 2);
}

.gap-x-4 {
  row-gap: calc(var(--gap) / 4);
}

.gap-x-8 {
  row-gap: calc(var(--gap) / 2);
}

.gap-x-10 {
  row-gap: calc(var(--gap) - 6px);
}

.gap-x-20 {
  row-gap: calc(var(--gap) + 4px);
}

.gap-x-0 {
  row-gap: 0;
}

.gap-y-1 {
  column-gap: var(--gap);
}

.gap-y-2 {
  column-gap: calc(var(--gap) * 2);
}

.gap-y-4 {
  column-gap: calc(var(--gap) / 4);
}

.gap-y-8 {
  column-gap: calc(var(--gap) / 2);
}

.gap-y-10 {
  column-gap: calc(var(--gap) - 6px);
}

.gap-y-20 {
  column-gap: calc(var(--gap) + 4px);
}

.gap-y-0 {
  column-gap: 0;
}

/* Text */
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}
.text-start {
  text-align: start;
}

.uppercase,
.tt-uppercase {
  text-transform: uppercase;
}

.lowercase,
.tt-lowercase {
  text-transform: lowercase;
}

.capitalize,
.tt-capitalize {
  text-transform: capitalize;
}

.tt-inherit {
  text-transform: inherit;
}
.tt-initial {
  text-transform: initial;
}
.tt-none {
  text-transform: none;
}

/* Border */
.br-1 {
  border-radius: var(--borderRadius);
}

.br-50 {
  border-radius: calc(var(--borderRadius) * 3);
}

.br-2 {
  border-radius: calc(var(--borderRadius) * 2);
}

.br-4 {
  border-radius: calc(var(--borderRadius) / 4);
}

.btr-4 {
  border-top-right-radius: calc(var(--borderRadius) / 4);
}
.btl-4 {
  border-top-left-radius: calc(var(--borderRadius) / 4);
}

.br-8 {
  border-radius: calc(var(--borderRadius) / 2);
}

.br-10 {
  border-radius: calc(var(--borderRadius) - 6px);
}

.br-20 {
  border-radius: calc(var(--borderRadius) + 4px);
}

.br-0,
.br-none {
  border-radius: 0;
}

.circle {
  border-radius: 50%;
}

.oval {
  border-radius: 100vmax;
}

.border-neutral {
  border: var(--borderWidth) var(--borderType) var(--clr-neutral-400);
}

.border-white {
  border: 1px solid var(--clr-white-400);
}

.border-white-100 {
  border: 1px solid var(--clr-white-100);
}

.border-white-200 {
  border: 1px solid var(--clr-white-200);
}
.border-white-300 {
  border: 1px solid var(--clr-white-300);
}
.border-white-400 {
  border: 1px solid var(--clr-white-400);
}
.border-white-500 {
  border: 1px solid var(--clr-white-500);
}
.border-white-600 {
  border: 1px solid var(--clr-white-600);
}
.border-white-700 {
  border: 1px solid var(--clr-white-700);
}
.border-white-800 {
  border: 1px solid var(--clr-white-800);
}
.border-white-900 {
  border: 1px solid var(--clr-white-900);
}

.border-black {
  border: var(--borderWidth) var(--borderType) var(--clr-black-400);
}

.border-primary {
  border: 1px solid var(--clr-primary-400);
}

.border-primary-heavy {
  border: 2px solid var(--clr-primary-400);
}

.border-secondary {
  border: var(--borderWidth) var(--borderType) var(--clr-secondary-400);
}

.border-bottom-white {
  border-bottom: var(--borderWidth) var(--borderType) var(--clr-white-400);
}

.border-bottom-black {
  border-bottom: 1px solid var(--clr-black-400);
}

.border-top-black {
  border-top: 1px solid var(--clr-black-400);
}

.border-accent {
  border: var(--borderWidth) var(--borderType) var(--clr-accent-400);
}

.border-heavy-orange {
  border: 5px var(--borderType) var(--clr-warning-900);
}

.border-danger {
  border: var(--borderWidth) var(--borderType) var(--clr-danger-400) !important;
}

.border-warning {
  border: var(--borderWidth) var(--borderType) var(--clr-warning-400);
}

.border-success {
  border: var(--borderWidth) var(--borderType) var(--clr-success-400);
}

.border-heavy-success {
  border: 5px var(--borderType) var(--clr-success-400);
}

.border-heavy-danger {
  border: 5px var(--borderType) var(--clr-danger-400);
}

.border-info {
  border: var(--borderWidth) var(--borderType) var(--clr-neutral-400);
}

.border-none {
  border: none;
}

.border-left-primary {
  border-left: 5px solid var(--clr-primary-400);
}

.border-bottom-white {
  border-bottom: 1px solid var(--clr-white-700);
}

.outline-none {
  outline: none;
}

.border-right-white {
  border-right: 1px solid var(--clr-white-400);
}
.border-right-black {
  border-right: 1px solid var(--clr-black-400);
}
.border-right-neutral {
  border-right: 1px solid var(--clr-neutral-400);
}
.border-right-primary {
  border-right: 1px solid var(--clr-primary-400);
}
.border-right-secondary {
  border-right: 1px solid var(--clr-secondary-400);
}
.border-right-accent {
  border-right: 1px solid var(--clr-accent-400);
}
.border-right-warning {
  border-right: 1px solid var(--clr-warning-400);
}
.border-right-success {
  border-right: 1px solid var(--clr-success-400);
}
.border-right-info {
  border-right: 1px solid var(--clr-info-400);
}
.border-right-danger {
  border-right: 1px solid var(--clr-danger-400);
}

/* Font */
.fs-logo {
  font-size: clamp(
    calc(var(--fontSize) * 1.75),
    calc(2vw + 1rem),
    calc(var(--fontSize) * 2)
  );
}

.fs-heading {
  font-size: clamp(
    calc(var(--fontSize) * 1.75),
    calc(2vw + 1rem),
    calc(var(--fontSize) * 4)
  );
}

.fs-subheading {
  font-size: clamp(
    calc(var(--fontSize) * 1.5),
    calc(2.5vw + 1rem),
    calc(var(--fontSize) * 3)
  );
}

.fs-subheading2 {
  font-size: clamp(
    calc(var(--fontSize) * 1.35),
    calc(2vw + 0.5rem),
    calc(var(--fontSize) * 2.5)
  );
}

.fs-tagline {
  font-size: clamp(
    calc(var(--fontSize) * 1.25),
    calc(1.5vw + 0.25rem),
    calc(var(--fontSize) * 2)
  );
}

.fs-popup-heading {
  font-size: clamp(
    calc(var(--fontSize) * 1.125),
    2vw,
    calc(var(--fontSize) * 1.5)
  );
}

.fs-button {
  font-size: clamp(16px, 2vw, 18px);
  line-height: 1.5;
}

.fs-regular {
  font-size: clamp(0.875rem, 2vw, var(--fontSize));
  line-height: 1.5;
}

.fs-link {
  font-size: calc(var(--fontSize) + 2px);
  line-height: 1.5;
}

.fs-link-heading {
  font-size: calc(var(--fontSize) + 4px);
  line-height: 1.5;
}

.fs-popup-btn {
  font-size: calc(var(--fontSize) - 2px);
  line-height: 1.5;
}

.fs-link-icon {
  font-size: calc(var(--fontSize) + 2px);
  line-height: 1.5;
}

.fs-icon {
  font-size: clamp(calc(var(--fontSize) * 2), 2.5vw, calc(var(--fontSize) * 3));
}

.fs-20 {
  font-size: 20px;
}

.fs-list-item-heading {
  font-size: clamp(
    calc(var(--fontSize) * 1.35),
    calc(2vw + 0.5rem),
    calc(var(--fontSize) * 2)
  );
  line-height: 1.15;
}

.fs-list-item-subheading {
  font-size: clamp(
    calc(var(--fontSize) * 1.15),
    calc(2vw + 0.25rem),
    calc(var(--fontSize) * 1.75)
  );
  line-height: 1.5;
}

.fs-list-item-text {
  font-size: clamp(
    calc(var(--fontSize) * 1),
    calc(2vw + 0.15rem),
    calc(var(--fontSize) * 1.35)
  );
  line-height: 1.5;
}

.fs-input {
  font-size: calc((var(--fontSize)));
  line-height: 1.5;
}

.fs-body {
  font-size: calc((var(--fontSize)) + 2px);
  line-height: 1.5;
}

.fs-medium {
  font-size: calc(var(--fontSize) + 3px);
  line-height: 1.5;
}

.fs-navbar-link {
  font-size: 22px;
  line-height: 1.5;
}

.fs-innerheading {
  font-size: clamp(calc(var(--fontSize) + 4px), 2vw, calc(var(--fontSize) * 3));
  line-height: 1.25;
}

.fs-normalheading {
  font-size: clamp(
    calc(var(--fontSize) + 12px),
    2.75vw,
    calc(var(--fontSize) * 3.75)
  );
  line-height: 1.25;
}

.fs-small {
  font-size: calc(var(--fontSize) - 2px);
  line-height: 1.75;
}

.fs-extrasmall {
  font-size: calc(var(--fontSize) - 4px);
}

.italic {
  font-style: italic;
}

.fw-100 {
  font-weight: 100;
}

.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

/* Height */
.min-h-100 {
  min-height: 100svh;
}

.h-38 {
  height: 38px;
}

.min-h-38 {
  min-height: 38px;
}

.h-100 {
  height: 100%;
}
.booking-packages-slab-qty {
  max-width: 75px;
  min-width: 75px;
}
.booking-packages-slab-material{
  max-width: 120px;
  min-width: 120px;
}
.select-Max-Width{
  min-width: 200px;
  max-width: 200px;
}

/* Width */
.w-100 {
  width: 100%;
}
.w-93 {
  width: 93.5%;
}
.w-80 {
  width: 80%;
}
.w-75 {
  width: 75%;
}
.fit-content {
  width: fit-content;
 }

.w-auto {
  width: auto !important;
}

.min-w-auto {
  min-width: auto;
}

.max-w-100 {
  max-width: 100%;
}

.max-w-50 {
  max-width: 50px;
}

.max-w-75 {
  max-width: 75px;
}

.max-w-120 {
  max-width: 120px;
}

.max-w-200 {
  max-width: 200px;
}

.max-w-300 {
  max-width: 300px;
}

.max-w-600 {
  max-width: 600px;
}

.max-w-400 {
  max-width: 400px;
}

.max-w-480 {
  max-width: 480px;
}

.max-w-768 {
  max-width: 768px;
}

.max-w-1024 {
  max-width: 1024px;
}

.max-w-1200 {
  max-width: 1200px;
}

.max-w-1250 {
  max-width: 1250px;
}

.max-w-900 {
  max-width: 900px;
}

.max-w-1440 {
  max-width: 1440px;
}

.max-w-1600 {
  max-width: 1600px;
}

.max-w-1920 {
  max-width: 1920px;
}

.max-content {
  width: max-content;
}

/* Containers/Wrapper */
.my-container {
  max-width: 1920px;
  width: calc(100% - var(--base));
  margin-inline: auto;
}

.small-container {
  max-width: 1024px;
  width: calc(100% - var(--base));
  margin-inline: auto;
}

.readable-container {
  max-width: 600px;
  width: calc(100% - var(--base));
  margin-inline: auto;
}

/* Position */
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-top {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -25%);
}

.fixed {
  position: fixed;
}

.fixed-top {
  position: fixed;
  top: 0;
}

.sticky {
  position: sticky;
}

.sticky-top {
  position: sticky;
  top: 0;
}

/* Display */
.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

.none {
  display: none;
}

/* Background */
.bg-none {
  background: none;
}

.bg-transparent {
  background-color: transparent;
}

/* Overlay */
.custom-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.custom-overlay-neutral {
  position: fixed;
  inset: 0;
  background-color: hsla(204, 20%, 60%, 0.5);
}

.custom-overlay-white {
  position: fixed;
  inset: 0;
  background-color: hsla(0, 0%, 100%, 0.5);
}

.custom-overlay-black {
  position: fixed;
  inset: 0;
  background-color: hsla(0, 0%, 0%, 0.5);
}

.custom-overlay-primary {
  position: fixed;
  inset: 0;
  background-color: hsla(204, 100%, 37%, 0.5);
}

.custom-overlay-secondary {
  position: fixed;
  inset: 0;
  background-color: hsla(219, 15%, 29%, 0.5);
}

.custom-overlay-accent {
  position: fixed;
  inset: 0;
  background-color: hsla(195, 100%, 42%, 0.5);
}

.custom-overlay-danger {
  position: fixed;
  inset: 0;
  background-color: hsla(0, 80%, 60%, 0.5);
}

.custom-overlay-warning {
  position: fixed;
  inset: 0;
  background-color: hsla(40, 100%, 45%, 0.5);
}

.custom-overlay-success {
  position: fixed;
  inset: 0;
  background-color: hsla(120, 70%, 45%, 0.5);
}

.custom-overlay-info {
  position: fixed;
  inset: 0;
  background-color: hsla(210, 90%, 60%, 0.5);
}

.custom-overlay-blur {
  backdrop-filter: blur(2px);
}

/* Shadow */
.shadow-none,
.box-shadow-none {
  box-shadow: none;
}
.shadow-small {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}

.shadow-light {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.shadow-regular {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.shadow-medium {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
}

.shadow-large {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
}

.drop-shadow-small {
  filter: drop-shadow(10px 5px 4px rgba(0, 0, 0, 0.5));
}

/* Pointer */
.pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

/* Button */
.button-white-primary {
  background-color: var(--clr-white-900);
  color: var(--clr-primary-400);
}

.button-orchid-primary {
  background-color: var(--clr-orchid-400);
  color: var(--clr-white-900);
}

.button-orchid-secondary {
  background-color: var(--clr-orchid-50);
  color: var(--clr-orchid-900);
}

.button-orchid-tertiary {
  background-color: transparent;
  color: var(--clr-orchid-900);
}

.button-neutral-primary {
  background-color: var(--clr-neutral-400);
  color: var(--clr-white-900);
}

.button-neutral-secondary {
  background-color: var(--clr-neutral-50);
  color: var(--clr-neutral-900);
}

.button-neutral-tertiary {
  color: var(--clr-neutral-900);
}

.button-black-primary {
  background-color: var(--clr-black-400);
  color: var(--clr-white-900);
}

.button-primary-primary {
  background-color: var(--clr-primary-400);
  color: var(--clr-white-900);
}

.button-primary-secondary {
  background-color: var(--clr-primary-50);
  color: var(--clr-primary-900);
}

.button-primary-tertiary {
  background-color: transparent;
  color: var(--clr-primary-900);
}

.button-secondary-primary {
  background-color: var(--clr-secondary-400);
  color: var(--clr-white-900);
}

.button-secondary-secondary {
  background-color: var(--clr-secondary-50);
  color: var(--clr-secondary-900);
}

.button-secondary-tertiary {
  background-color: transparent;
  color: var(--clr-secondary-900);
}

.button-accent-primary {
  background-color: var(--clr-accent-400);
  color: var(--clr-white-900);
}

.button-accent-secondary {
  background-color: var(--clr-accent-50);
  color: var(--clr-accent-900);
}

.button-accent-tertiary {
  background-color: transparent;
  color: var(--clr-accent-900);
}

.button-danger-primary {
  background-color: var(--clr-danger-400);
  color: var(--clr-white-900);
}

.button-danger-secondary {
  background-color: var(--clr-danger-50);
  color: var(--clr-danger-900);
}

.button-danger-tertiary {
  background-color: transparent;
  color: var(--clr-danger-900);
}

.button-warning-primary {
  background-color: var(--clr-warning-400);
  color: var(--clr-white-900);
}

.button-warning-secondary {
  background-color: var(--clr-warning-50);
  color: var(--clr-warning-900);
}

.button-warning-tertiary {
  background-color: transparent;
  color: var(--clr-warning-900);
}

.button-success-primary {
  background-color: var(--clr-success-400);
  color: var(--clr-white-900);
}

.button-success-secondary {
  background-color: var(--clr-success-50);
  color: var(--clr-success-900);
}

.button-success-tertiary {
  background-color: transparent;
  color: var(--clr-success-900);
}

.button-info-primary {
  background-color: var(--clr-info-400);
  color: var(--clr-white-900);
}

.button-info-secondary {
  background-color: var(--clr-info-50);
  color: var(--clr-info-900);
}

.button-info-tertiary {
  background-color: transparent;
  color: var(--clr-info-900);
}

.button {
  transition: all ease 200ms;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0)
  );
  transition: all ease 400ms;
}

.button:hover::before,
.button:focus::before,
.button:active::before {
  left: 100%;
}

.button:hover {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.7);
}

.button:focus-visible {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.7);
  filter: brightness(95%) contrast(95%) hue-rotate(5deg);
}

/* User Select */
.user-select-none {
  user-select: none;
}

.pointer-events-none {
  pointer-events: none;
}

/* Overflow */
.overflow-hidden {
  overflow: hidden;
}

/* Aspect Ratio */
.aspect-ratio-1,
.aspect-ratio-square {
  aspect-ratio: 1;
}

/* Text Transform */
.hover-underline:hover {
  text-decoration: underline;
}
.text-underline {
  text-decoration: underline;
}

.text-underline {
  text-decoration: underline;
}

/* Text Shadow */
.text-shadow-small {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.fancy {
  position: relative;
  white-space: nowrap;

  &:after {
    --deco-height: 0.3125em;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: calc(var(--deco-height) * -0.625);
    height: var(--deco-height);
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M-17 30.5C-1 22 72-4 54 13 37.9 28.2-2.5 57.5 16 55.5s72-29 104-40' stroke='%2300FDCF' stroke-width='10'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h100v64H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    background-size: auto 100%;
    background-repeat: round;
    background-position: 0em;
  }
}

.glass-effect {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border-radius: var(--borderRadius);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.max-h-100 {
  max-height: 100%;
}

.max-h-435 {
  max-height: 435px;
}
.max-h-20 {
  max-height: 23rem;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

/* Line Height */
.line-height-100 {
  line-height: 100%;
}

.none-if-empty:empty {
  display: none;
}

.custom-img {
  max-width: 100%;
  display: block;
}

.ar-1 {
  aspect-ratio: 1;
}

/* Paper */
.paper {
  /* border-radius: calc(var(--borderRadius) / 4);
  padding: var(--padding);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  border: 1px solid var(--clr-white-500); */
  background-color: var(--clr-white-900);
  padding: var(--padding);
  border-radius: calc(var(--borderRadius) / 4);
  border: 1px solid var(--border-light-color, "#ebebeb");
}

.paper-elevation-1 {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}

.paper-elevation-2 {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
}

.paper-elevation-3 {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.5);
}

.paper-elevation-4 {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
}

.paper-shadow {
  background: rgb(0, 0, 0,0.01);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: var(--padding);
}

.paper-shadow-without-padding {
  background: rgb(0, 0, 0,0.01);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.my-custom-responsive-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 1fr));
}

.overflow-x-auto {
  overflow-x: auto;
}
.overflow-x-visible {
  overflow-x: visible;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-x-initial {
  overflow-x: initial;
}
.overflow-x-inherit {
  overflow-x: inherit;
}
.overflow-x-scroll {
  overflow-x: scroll;
}
.overflow-auto {
  overflow: auto;
}
.overflow-visible {
  overflow: visible;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-initial {
  overflow: initial;
}
.overflow-inherit {
  overflow: inherit;
}
.overflow-scroll {
  overflow: scroll;
}

.text-transparent {
  color: transparent;
}

.fs-h1 {
  font-size: clamp(2rem, 5vw + 1rem, 4rem);
  line-height: 1;
}

.fs-h2 {
  font-size: clamp(1.75rem, 4.5vw + 0.875rem, 3.5rem);
  line-height: 1.15;
}

.fs-h3 {
  font-size: clamp(1.5rem, 4vw + 0.75rem, 3rem);
  line-height: 1.35;
}

.fs-h4 {
  font-size: clamp(1.25rem, 3vw + 0.65rem, 2.5rem);
  line-height: 1.35;
}

.fs-h5 {
  font-size: clamp(1rem, 3vw + 0.5rem, 2rem);
  line-height: 1.45;
}

.fs-h6 {
  font-size: clamp(0.875rem, 2.5vw + 0.375rem, 1.5rem);
  line-height: 1.5;
}

.taptap-focus-ring:focus {
  box-shadow: 0 0 0 .2rem var(--taptap-focus-ring-color);
}

.py-5 {
  padding-block: 5px;
}

.animation-fade-in {
  animation: animationFadeIn 1s ease; /* duration and easing */
  animation-fill-mode: forwards; /* Ensures the final state persists */
}

.animation-fade-in-slide {
  animation: animationFadeInSlide 1s ease; /* duration and easing */
  animation-fill-mode: forwards; /* Ensures the final state persists */
}

.h-30 {
  height: 30px;
}

.w-30 {
  width: 30px;
}

@keyframes animationFadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}
@keyframes animationFadeInSlide {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0px);
  }
}

.animation-slide-in {
  animation: animationSlideIn 300ms ease; /* duration and easing */
  animation-fill-mode: forwards; /* Ensures the final state persists */
}
.animation-scale-in {
  animation: animationScaleIn 100ms ease-in-out; /* duration and easing */
  animation-fill-mode: forwards; /* Ensures the final state persists */
  transform-origin: top;
}

.animation-delay-300ms {
  animation-delay: 300ms;
}

@keyframes animationSlideIn {
  from {
      right: -100%;
  }
  to {
      right: 0;
  }
}

@keyframes animationScaleIn {
  from {
      scale: 3 0;
  }
  to {
      right: 1;
  }
}

.hover-brightness-effect:hover {
  filter: brightness(95%);
}

.hover-bg-white-800:hover {
  background-color: var(--clr-white-800);
}

/* transitions.css */
.transition-page-enter {
  opacity: 0;
  /* transform: translateX(100%); */
}
.transition-page-enter-active {
  opacity: 1;
  /* transform: translateX(0); */
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.transition-page-exit {
  opacity: 1;
  /* transform: translateX(0); */
}
.transition-page-exit-active {
  opacity: 0;
  /* transform: translateX(-100%); */
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.light-paper {
  background-color: var(--clr-white-900);
  /* background-color: #f8fafd; */
  padding: var(--padding);
  /* border-radius: var(--borderRadius); */
  border-radius: calc(var(--borderRadius) / 4);
  border: 1px solid var(--border-light-color, "#ebebeb");
}

.light-paper-border {
  border: 1px solid var(--border-light-color, "#ebebeb");
}

.ring-on-focus:focus {
  box-shadow: 0 0 0 0.2rem var(--taptap-focus-ring-color);
}

.bg-white-light {
  /* background-color: #f8f7f7; */
  background-color: var(--bg-white-light, hsl(220, 60%, 97%));
}

.z-10 {
  z-index: 10;
}

.bg-primary-light {
  background: hsl(204deg 100% 85.88%);
  box-shadow: 0 1px 1px  var(--clr-primary-900);
}

.border-primary-light {
  border: 1px solid hsl(204deg 100% 83.53%);
}

.position-fixed {
  position: fixed;
}

.z-1000 {
  z-index: 1000
}

.inset-0 {
  inset: 0;
}

.no-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari */
}

.goog-te-gadget {
  color: transparent !important;
}

.goog-te-gadget > span {
  display: none;
}

.goog-te-gadget select {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid var(--clr-white-400);
  outline: none;
}

.inset-0 {
  inset: 0;
}

.fs-c-sm {
  font-size: calc(var(--fs-c) - 2px);
}

.fs-c-base {
  font-size: calc(var(--fs-c));
}

.fs-c-md {
  font-size: calc(var(--fs-c) + 2px);
}

.fs-c-lg {
  font-size: calc(var(--fs-c) + 4px);
}

.fs-c-xl {
  font-size: calc(var(--fs-c) + 6px);
}