/* system color CSS */
:root {
    /* Neutral Color */
    --clr-base: 5;
    --clr-neutral-50: hsl(204, 20%, 100%);
    --clr-neutral-100: hsl(204, 20%, 90%);
    --clr-neutral-200: hsl(204, 20%, 80%);
    --clr-neutral-300: hsl(204, 20%, 70%);
    --clr-neutral-400: hsl(204, 20%, 60%);
    --clr-neutral-500: hsl(204, 20%, 50%);
    --clr-neutral-600: hsl(204, 20%, 40%);
    --clr-neutral-700: hsl(204, 20%, 30%);
    --clr-neutral-800: hsl(204, 20%, 20%);
    --clr-neutral-900: hsl(204, 20%, 10%);
    
    /* White Color */
    --clr-white-50: hsl(0, 0%, 55%);
    --clr-white-100: hsl(0, 0%, 60%);
    --clr-white-200: hsl(0, 0%, 65%);
    --clr-white-300: hsl(0, 0%, 70%);
    --clr-white-400: hsl(0, 0%, 75%);
    --clr-white-500: hsl(0, 0%, 80%);
    --clr-white-600: hsl(0, 0%, 92%);
    --clr-white-700: hsl(0, 0%, 95%);
    --clr-white-800: hsl(0, 0%, 98%);
    --clr-white-900: hsl(0, 0%, 100%);

    /* Black Color */
    --clr-black-50: hsl(0, 0%, 45%);
    --clr-black-100: hsl(0, 0%, 40%);
    --clr-black-200: hsl(0, 0%, 35%);
    --clr-black-300: hsl(0, 0%, 30%);
    --clr-black-400: hsl(0, 0%, 25%);
    --clr-black-500: hsl(0, 0%, 20%);
    --clr-black-600: hsl(0, 0%, 15%);
    --clr-black-700: hsl(0, 78%, 2%);
    --clr-black-800: hsl(0, 0%, 5%);
    --clr-black-900: hsl(0, 0%, 0%);

    /* Primary Color */
    /* Hex Code: #007acc */
    --clr-primary-50: hsl(var(--clr-primary-hue, 204), 100%, 90%);
    --clr-primary-100: hsl(var(--clr-primary-hue, 204), 100%, 55%);
    --clr-primary-200: hsl(var(--clr-primary-hue, 204), 100%, 50%);
    --clr-primary-300: hsl(var(--clr-primary-hue, 204), 100%, 45%);
    --clr-primary-400: hsl(var(--clr-primary-hue, 204), 100%, 40%);
    --clr-primary-500: hsl(var(--clr-primary-hue, 204), 100%, 35%);
    --clr-primary-600: hsl(var(--clr-primary-hue, 204), 100%, 30%);
    --clr-primary-700: hsl(var(--clr-primary-hue, 204), 100%, 25%);
    --clr-primary-800: hsl(var(--clr-primary-hue, 204), 100%, 20%);
    --clr-primary-900: hsl(var(--clr-primary-hue, 204), 100%, 15%);

    /* --clr-secondary-hue: 173;
    --clr-secondary-saturation: 60;
    --clr-secondary-lightness: 50;
    --clr-secondary-50: hsl(var(--clr-secondary-hue) var(--clr-secondary-saturation) 90);
    --clr-secondary-100: hsl(var(--clr-secondary-hue) var(--clr-secondary-saturation) calc(var(--clr-secondary-lightness) + calc(var(--clr-base) * 3)));
    --clr-secondary-200: hsl(var(--clr-secondary-hue) var(--clr-secondary-saturation) calc(var(--clr-secondary-lightness) + calc(var(--clr-base) * 2)));
    --clr-secondary-300: hsl(var(--clr-secondary-hue) var(--clr-secondary-saturation) calc(var(--clr-secondary-lightness) + calc(var(--clr-base) * 1)));
    --clr-secondary-400: hsl(var(--clr-secondary-hue) var(--clr-secondary-saturation) var(--clr-secondary-lightness));
    --clr-secondary-500: hsl(var(--clr-secondary-hue) var(--clr-secondary-saturation) calc(var(--clr-secondary-lightness) - calc(var(--clr-base) * 1)));
    --clr-secondary-600: hsl(var(--clr-secondary-hue) var(--clr-secondary-saturation) calc(var(--clr-secondary-lightness) - calc(var(--clr-base) * 2)));
    --clr-secondary-700: hsl(var(--clr-secondary-hue) var(--clr-secondary-saturation) calc(var(--clr-secondary-lightness) - calc(var(--clr-base) * 3)));
    --clr-secondary-800: hsl(var(--clr-secondary-hue) var(--clr-secondary-saturation) calc(var(--clr-secondary-lightness) - calc(var(--clr-base) * 4)));
    --clr-secondary-900: hsl(var(--clr-secondary-hue) var(--clr-secondary-saturation) calc(var(--clr-secondary-lightness) - calc(var(--clr-base) * 5))); */

    /* Secondary Color */
    --clr-secondary-50: hsl(var(--clr-secondary-hue, 172), 31%, 90%);
    --clr-secondary-100: hsl(var(--clr-secondary-hue, 172), 31%, 86%);
    --clr-secondary-200: hsl(var(--clr-secondary-hue, 172), 31%, 80%);
    --clr-secondary-300: hsl(var(--clr-secondary-hue, 172), 31%, 78%);
    --clr-secondary-400: hsl(var(--clr-secondary-hue, 172), 31%, 76%);
    --clr-secondary-500: hsl(var(--clr-secondary-hue, 172), 31%, 74%);
    --clr-secondary-600: hsl(var(--clr-secondary-hue, 172), 31%, 72%);
    --clr-secondary-700: hsl(var(--clr-secondary-hue, 172), 31%, 70%);
    --clr-secondary-800: hsl(var(--clr-secondary-hue, 172), 31%, 68%);
    --clr-secondary-900: hsl(var(--clr-secondary-hue, 172), 31%, 40%);

    /* Accent Color */
    --clr-accent-50: hsl(195, 100%, 90%);
    --clr-accent-100: hsl(195, 100%, 57%);
    --clr-accent-200: hsl(195, 100%, 52%);
    --clr-accent-300: hsl(195, 100%, 47%);
    --clr-accent-400: hsl(195, 100%, 42%);
    --clr-accent-500: hsl(195, 100%, 37%);
    --clr-accent-600: hsl(195, 100%, 32%);
    --clr-accent-700: hsl(195, 100%, 27%);
    --clr-accent-800: hsl(195, 100%, 22%);
    --clr-accent-900: hsl(195, 100%, 17%);

    /* Danger Color */
    --clr-danger-50: hsl(0, 80%, 93%);
    --clr-danger-100: hsl(0, 80%, 75%);
    --clr-danger-200: hsl(0, 80%, 70%);
    --clr-danger-300: hsl(0, 80%, 65%);
    --clr-danger-400: hsl(0, 80%, 60%);
    --clr-danger-500: hsl(0, 80%, 55%);
    --clr-danger-600: hsl(0, 80%, 50%);
    --clr-danger-700: hsl(0, 80%, 45%);
    --clr-danger-800: hsl(0, 80%, 40%);
    --clr-danger-900: hsl(0, 80%, 35%);

    /* Warning Color */
    --clr-warning-50: hsl(40, 100%, 90%);
    --clr-warning-100: hsl(40, 100%, 65%);
    --clr-warning-200: hsl(40, 100%, 60%);
    --clr-warning-300: hsl(40, 100%, 55%);
    --clr-warning-400: hsl(40, 100%, 50%);
    --clr-warning-500: hsl(40, 100%, 45%);
    --clr-warning-600: hsl(40, 100%, 40%);
    --clr-warning-700: hsl(40, 100%, 35%);
    --clr-warning-800: hsl(40, 100%, 30%);
    --clr-warning-900: hsl(40, 100%, 25%);

    /* Success Color */
    --clr-success-50: hsl(120, 70%, 90%);
    --clr-success-100: hsl(120, 70%, 60%);
    --clr-success-200: hsl(120, 70%, 55%);
    --clr-success-300: hsl(120, 70%, 50%);
    --clr-success-400: hsl(120, 70%, 45%);
    --clr-success-500: hsl(120, 70%, 40%);
    --clr-success-600: hsl(120, 70%, 35%);
    --clr-success-700: hsl(120, 70%, 30%);
    --clr-success-800: hsl(120, 70%, 25%);
    --clr-success-900: hsl(120, 70%, 20%);

    /* Info Color */
    --clr-info-50: hsl(210, 90%, 90%);
    --clr-info-100: hsl(210, 90%, 75%);
    --clr-info-200: hsl(210, 90%, 70%);
    --clr-info-300: hsl(210, 90%, 65%);
    --clr-info-400: hsl(210, 90%, 60%);
    --clr-info-500: hsl(210, 90%, 55%);
    --clr-info-600: hsl(210, 90%, 50%);
    --clr-info-700: hsl(210, 90%, 45%);
    --clr-info-800: hsl(210, 90%, 40%);
    --clr-info-900: hsl(210, 90%, 35%);

    --clr-orchid-50: hsl(291, 64%, 90%);
    --clr-orchid-100: hsl(291, 64%, 57%);
    --clr-orchid-200: hsl(291, 64%, 52%);
    --clr-orchid-300: hsl(291, 64%, 47%);
    --clr-orchid-400: hsl(291, 64%, 42%);
    --clr-orchid-500: hsl(291, 64%, 37%);
    --clr-orchid-600: hsl(291, 64%, 32%);
    --clr-orchid-700: hsl(291, 64%, 27%);
    --clr-orchid-800: hsl(291, 64%, 22%);
    --clr-orchid-900: hsl(291, 64%, 17%);
}

.text-green {
    color: green;
}

.text-red {
    color: red;
}

/* Background Color Utilities */

/* Neutral Background Color */
.bg-neutral-50 { background-color: var(--clr-neutral-50); }
.bg-neutral-100 { background-color: var(--clr-neutral-100); }
.bg-neutral-200 { background-color: var(--clr-neutral-200); }
.bg-neutral-300 { background-color: var(--clr-neutral-300); }
.bg-neutral-400 { background-color: var(--clr-neutral-400); }
.bg-neutral-500 { background-color: var(--clr-neutral-500); }
.bg-neutral-600 { background-color: var(--clr-neutral-600); }
.bg-neutral-700 { background-color: var(--clr-neutral-700); }
.bg-neutral-800 { background-color: var(--clr-neutral-800); }
.bg-neutral-900 { background-color: var(--clr-neutral-900); }


/* Orchid Background Color */
.bg-orchid-50 { background-color: var(--clr-orchid-50); }
.bg-orchid-100 { background-color: var(--clr-orchid-100); }
.bg-orchid-200 { background-color: var(--clr-orchid-200); }
.bg-orchid-300 { background-color: var(--clr-orchid-300); }
.bg-orchid-400 { background-color: var(--clr-orchid-400); }
.bg-orchid-500 { background-color: var(--clr-orchid-500); }
.bg-orchid-600 { background-color: var(--clr-orchid-600); }
.bg-orchid-700 { background-color: var(--clr-orchid-700); }
.bg-orchid-800 { background-color: var(--clr-orchid-800); }
.bg-orchid-900 { background-color: var(--clr-orchid-900); }

/* White Background Color */
.bg-white-50 { background-color: var(--clr-white-50); }
.bg-white-100 { background-color: var(--clr-white-100); }
.bg-white-200 { background-color: var(--clr-white-200); }
.bg-white-300 { background-color: var(--clr-white-300); }
.bg-white-400 { background-color: var(--clr-white-400); }
.bg-white-500 { background-color: var(--clr-white-500); }
.bg-white-600 { background-color: var(--clr-white-600); }
.bg-white-700 { background-color: var(--clr-white-700); }
.bg-white-800 { background-color: var(--clr-white-800); }
.bg-white-900 { background-color: var(--clr-white-900); }

/* Black Background Color */
.bg-black-50 { background-color: var(--clr-black-50); }
.bg-black-100 { background-color: var(--clr-black-100); }
.bg-black-200 { background-color: var(--clr-black-200); }
.bg-black-300 { background-color: var(--clr-black-300); }
.bg-black-400 { background-color: var(--clr-black-400); }
.bg-black-500 { background-color: var(--clr-black-500); }
.bg-black-600 { background-color: var(--clr-black-600); }
.bg-black-700 { background-color: var(--clr-black-700); }
.bg-black-800 { background-color: var(--clr-black-800); }
.bg-black-900 { background-color: var(--clr-black-900); }

/* Primary Background Color */
.bg-primary-50 { background-color: var(--clr-primary-50); }
.bg-primary-100 { background-color: var(--clr-primary-100); }
.bg-primary-200 { background-color: var(--clr-primary-200); }
.bg-primary-300 { background-color: var(--clr-primary-300); }
.bg-primary-400 { background-color: var(--clr-primary-400) !important; }
.bg-primary-500 { background-color: var(--clr-primary-500); }
.bg-primary-600 { background-color: var(--clr-primary-600); }
.bg-primary-700 { background-color: var(--clr-primary-700); }
.bg-primary-800 { background-color: var(--clr-primary-800); }
.bg-primary-900 { background-color: var(--clr-primary-900); }

/* Secondary Background Color */
.bg-secondary-50 { background-color: var(--clr-secondary-50); }
.bg-secondary-100 { background-color: var(--clr-secondary-100); }
.bg-secondary-200 { background-color: var(--clr-secondary-200); }
.bg-secondary-300 { background-color: var(--clr-secondary-300); }
.bg-secondary-400 { background-color: var(--clr-secondary-400); }
.bg-secondary-500 { background-color: var(--clr-secondary-500); }
.bg-secondary-600 { background-color: var(--clr-secondary-600); }
.bg-secondary-700 { background-color: var(--clr-secondary-700); }
.bg-secondary-800 { background-color: var(--clr-secondary-800); }
.bg-secondary-900 { background-color: var(--clr-secondary-900); }

/* Accent Background Color */
.bg-accent-50 { background-color: var(--clr-accent-50); }
.bg-accent-100 { background-color: var(--clr-accent-100); }
.bg-accent-200 { background-color: var(--clr-accent-200); }
.bg-accent-300 { background-color: var(--clr-accent-300); }
.bg-accent-400 { background-color: var(--clr-accent-400); }
.bg-accent-500 { background-color: var(--clr-accent-500); }
.bg-accent-600 { background-color: var(--clr-accent-600); }
.bg-accent-700 { background-color: var(--clr-accent-700); }
.bg-accent-800 { background-color: var(--clr-accent-800); }
.bg-accent-900 { background-color: var(--clr-accent-900); }

/* Danger Background Color */
.bg-danger-50 { background-color: var(--clr-danger-50); }
.bg-danger-100 { background-color: var(--clr-danger-100); }
.bg-danger-200 { background-color: var(--clr-danger-200); }
.bg-danger-300 { background-color: var(--clr-danger-300); }
.bg-danger-400 { background-color: var(--clr-danger-400); }
.bg-danger-500 { background-color: var(--clr-danger-500); }
.bg-danger-600 { background-color: var(--clr-danger-600); }
.bg-danger-700 { background-color: var(--clr-danger-700); }
.bg-danger-800 { background-color: var(--clr-danger-800); }
.bg-danger-900 { background-color: var(--clr-danger-900); }

/* Warning Background Color */
.bg-warning-50 { background-color: var(--clr-warning-50) !important; }
.bg-warning-100 { background-color: var(--clr-warning-100); }
.bg-warning-200 { background-color: var(--clr-warning-200); }
.bg-warning-300 { background-color: var(--clr-warning-300); }
.bg-warning-400 { background-color: var(--clr-warning-400); }
.bg-warning-500 { background-color: var(--clr-warning-500); }
.bg-warning-600 { background-color: var(--clr-warning-600); }
.bg-warning-700 { background-color: var(--clr-warning-700); }
.bg-warning-800 { background-color: var(--clr-warning-800); }
.bg-warning-900 { background-color: var(--clr-warning-900); }

/* Success Background Color */
.bg-success-50 { background-color: var(--clr-success-50) !important; }
.bg-success-100 { background-color: var(--clr-success-100); }
.bg-success-200 { background-color: var(--clr-success-200); }
.bg-success-300 { background-color: var(--clr-success-300); }
.bg-success-400 { background-color: var(--clr-success-400); }
.bg-success-500 { background-color: var(--clr-success-500); }
.bg-success-600 { background-color: var(--clr-success-600); }
.bg-success-700 { background-color: var(--clr-success-700); }
.bg-success-800 { background-color: var(--clr-success-800); }
.bg-success-900 { background-color: var(--clr-success-900); }

/* Info Background Color */
.bg-info-50 { background-color: var(--clr-info-50); }
.bg-info-100 { background-color: var(--clr-info-100); }
.bg-info-200 { background-color: var(--clr-info-200); }
.bg-info-300 { background-color: var(--clr-info-300); }
.bg-info-400 { background-color: var(--clr-info-400); }
.bg-info-500 { background-color: var(--clr-info-500); }
.bg-info-600 { background-color: var(--clr-info-600); }
.bg-info-700 { background-color: var(--clr-info-700); }
.bg-info-800 { background-color: var(--clr-info-800); }
.bg-info-900 { background-color: var(--clr-info-900); }

/* Text Color Utilities */

/* Neutral Text Color */
.text-neutral-50 { color: var(--clr-neutral-50); }
.text-neutral-100 { color: var(--clr-neutral-100); }
.text-neutral-200 { color: var(--clr-neutral-200); }
.text-neutral-300 { color: var(--clr-neutral-300); }
.text-neutral-400 { color: var(--clr-neutral-400); }
.text-neutral-500 { color: var(--clr-neutral-500); }
.text-neutral-600 { color: var(--clr-neutral-600); }
.text-neutral-700 { color: var(--clr-neutral-700); }
.text-neutral-800 { color: var(--clr-neutral-800); }
.text-neutral-900 { color: var(--clr-neutral-900); }

/* White Text Color */
.text-white-50 { color: var(--clr-white-50); }
.text-white-100 { color: var(--clr-white-100); }
.text-white-200 { color: var(--clr-white-200); }
.text-white-300 { color: var(--clr-white-300); }
.text-white-400 { color: var(--clr-white-400); }
.text-white-500 { color: var(--clr-white-500); }
.text-white-600 { color: var(--clr-white-600); }
.text-white-700 { color: var(--clr-white-700); }
.text-white-800 { color: var(--clr-white-800); }
.text-white-900 { color: var(--clr-white-900); }

/* Black Text Color */
.text-black-50 { color: var(--clr-black-50); }
.text-black-100 { color: var(--clr-black-100); }
.text-black-200 { color: var(--clr-black-200); }
.text-black-300 { color: var(--clr-black-300); }
.text-black-400 { color: var(--clr-black-400); }
.text-black-500 { color: var(--clr-black-500); }
.text-black-600 { color: var(--clr-black-600); }
.text-black-700 { color: var(--clr-black-700); }
.text-black-800 { color: var(--clr-black-800); }
.text-black-900 { color: var(--clr-black-900); }

/* Primary Text Color */
.text-primary-50 { color: var(--clr-primary-50); }
.text-primary-100 { color: var(--clr-primary-100); }
.text-primary-200 { color: var(--clr-primary-200); }
.text-primary-300 { color: var(--clr-primary-300); }
.text-primary-400 { color: var(--clr-primary-400); }
.text-primary-500 { color: var(--clr-primary-500); }
.text-primary-600 { color: var(--clr-primary-600); }
.text-primary-700 { color: var(--clr-primary-700); }
.text-primary-800 { color: var(--clr-primary-800); }
.text-primary-900 { color: var(--clr-primary-900); }

/* Secondary Text Color */
.text-secondary-50 { color: var(--clr-secondary-50); }
.text-secondary-100 { color: var(--clr-secondary-100); }
.text-secondary-200 { color: var(--clr-secondary-200); }
.text-secondary-300 { color: var(--clr-secondary-300); }
.text-secondary-400 { color: var(--clr-secondary-400); }
.text-secondary-500 { color: var(--clr-secondary-500); }
.text-secondary-600 { color: var(--clr-secondary-600); }
.text-secondary-700 { color: var(--clr-secondary-700); }
.text-secondary-800 { color: var(--clr-secondary-800); }
.text-secondary-900 { color: var(--clr-secondary-900); }

/* Accent Text Color */
.text-accent-50 { color: var(--clr-accent-50); }
.text-accent-100 { color: var(--clr-accent-100); }
.text-accent-200 { color: var(--clr-accent-200); }
.text-accent-300 { color: var(--clr-accent-300); }
.text-accent-400 { color: var(--clr-accent-400); }
.text-accent-500 { color: var(--clr-accent-500); }
.text-accent-600 { color: var(--clr-accent-600); }
.text-accent-700 { color: var(--clr-accent-700); }
.text-accent-800 { color: var(--clr-accent-800); }
.text-accent-900 { color: var(--clr-accent-900); }

/* Danger Text Color */
.text-danger-50 { color: var(--clr-danger-50); }
.text-danger-100 { color: var(--clr-danger-100); }
.text-danger-200 { color: var(--clr-danger-200); }
.text-danger-300 { color: var(--clr-danger-300); }
.text-danger-400 { color: var(--clr-danger-400); }
.text-danger-500 { color: var(--clr-danger-500); }
.text-danger-600 { color: var(--clr-danger-600); }
.text-danger-700 { color: var(--clr-danger-700); }
.text-danger-800 { color: var(--clr-danger-800); }
.text-danger-900 { color: var(--clr-danger-900); }

/* Warning Text Color */
.text-warning-50 { color: var(--clr-warning-50); }
.text-warning-100 { color: var(--clr-warning-100); }
.text-warning-200 { color: var(--clr-warning-200); }
.text-warning-300 { color: var(--clr-warning-300); }
.text-warning-400 { color: var(--clr-warning-400); }
.text-warning-500 { color: var(--clr-warning-500); }
.text-warning-600 { color: var(--clr-warning-600); }
.text-warning-700 { color: var(--clr-warning-700); }
.text-warning-800 { color: var(--clr-warning-800); }
.text-warning-900 { color: var(--clr-warning-900); }

/* Success Text Color */
.text-success-50 { color: var(--clr-success-50); }
.text-success-100 { color: var(--clr-success-100); }
.text-success-200 { color: var(--clr-success-200); }
.text-success-300 { color: var(--clr-success-300); }
.text-success-400 { color: var(--clr-success-400); }
.text-success-500 { color: var(--clr-success-500); }
.text-success-600 { color: var(--clr-success-600); }
.text-success-700 { color: var(--clr-success-700); }
.text-success-800 { color: var(--clr-success-800); }
.text-success-900 { color: var(--clr-success-900); }

/* Info Text Color */
.text-info-50 { color: var(--clr-info-50); }
.text-info-100 { color: var(--clr-info-100); }
.text-info-200 { color: var(--clr-info-200); }
.text-info-300 { color: var(--clr-info-300); }
.text-info-400 { color: var(--clr-info-400); }
.text-info-500 { color: var(--clr-info-500); }
.text-info-600 { color: var(--clr-info-600); }
.text-info-700 { color: var(--clr-info-700); }
.text-info-800 { color: var(--clr-info-800); }
.text-info-900 { color: var(--clr-info-900); }

/* Info Text Color */
.text-orchid-50 { color: var(--clr-orchid-50); }
.text-orchid-100 { color: var(--clr-orchid-100); }
.text-orchid-200 { color: var(--clr-orchid-200); }
.text-orchid-300 { color: var(--clr-orchid-300); }
.text-orchid-400 { color: var(--clr-orchid-400); }
.text-orchid-500 { color: var(--clr-orchid-500); }
.text-orchid-600 { color: var(--clr-orchid-600); }
.text-orchid-700 { color: var(--clr-orchid-700); }
.text-orchid-800 { color: var(--clr-orchid-800); }
.text-orchid-900 { color: var(--clr-orchid-900); }

@media (width <= 768px) {
    /* Background Color Utilities */

/* Neutral Background Color */
.t-bg-neutral-50 { background-color: var(--clr-neutral-50); }
.t-bg-neutral-100 { background-color: var(--clr-neutral-100); }
.t-bg-neutral-200 { background-color: var(--clr-neutral-200); }
.t-bg-neutral-300 { background-color: var(--clr-neutral-300); }
.t-bg-neutral-400 { background-color: var(--clr-neutral-400); }
.t-bg-neutral-500 { background-color: var(--clr-neutral-500); }
.t-bg-neutral-600 { background-color: var(--clr-neutral-600); }
.t-bg-neutral-700 { background-color: var(--clr-neutral-700); }
.t-bg-neutral-800 { background-color: var(--clr-neutral-800); }
.t-bg-neutral-900 { background-color: var(--clr-neutral-900); }

/* White Background Color */
.t-bg-white-50 { background-color: var(--clr-white-50); }
.t-bg-white-100 { background-color: var(--clr-white-100); }
.t-bg-white-200 { background-color: var(--clr-white-200); }
.t-bg-white-300 { background-color: var(--clr-white-300); }
.t-bg-white-400 { background-color: var(--clr-white-400); }
.t-bg-white-500 { background-color: var(--clr-white-500); }
.t-bg-white-600 { background-color: var(--clr-white-600); }
.t-bg-white-700 { background-color: var(--clr-white-700); }
.t-bg-white-800 { background-color: var(--clr-white-800); }
.t-bg-white-900 { background-color: var(--clr-white-900); }

/* Black Background Color */
.t-bg-black-50 { background-color: var(--clr-black-50); }
.t-bg-black-100 { background-color: var(--clr-black-100); }
.t-bg-black-200 { background-color: var(--clr-black-200); }
.t-bg-black-300 { background-color: var(--clr-black-300); }
.t-bg-black-400 { background-color: var(--clr-black-400); }
.t-bg-black-500 { background-color: var(--clr-black-500); }
.t-bg-black-600 { background-color: var(--clr-black-600); }
.t-bg-black-700 { background-color: var(--clr-black-700); }
.t-bg-black-800 { background-color: var(--clr-black-800); }
.t-bg-black-900 { background-color: var(--clr-black-900); }

/* Primary Background Color */
.t-bg-primary-50 { background-color: var(--clr-primary-50); }
.t-bg-primary-100 { background-color: var(--clr-primary-100); }
.t-bg-primary-200 { background-color: var(--clr-primary-200); }
.t-bg-primary-300 { background-color: var(--clr-primary-300); }
.t-bg-primary-400 { background-color: var(--clr-primary-400); }
.t-bg-primary-500 { background-color: var(--clr-primary-500); }
.t-bg-primary-600 { background-color: var(--clr-primary-600); }
.t-bg-primary-700 { background-color: var(--clr-primary-700); }
.t-bg-primary-800 { background-color: var(--clr-primary-800); }
.t-bg-primary-900 { background-color: var(--clr-primary-900); }

/* Secondary Background Color */
.t-bg-secondary-50 { background-color: var(--clr-secondary-50); }
.t-bg-secondary-100 { background-color: var(--clr-secondary-100); }
.t-bg-secondary-200 { background-color: var(--clr-secondary-200); }
.t-bg-secondary-300 { background-color: var(--clr-secondary-300); }
.t-bg-secondary-400 { background-color: var(--clr-secondary-400); }
.t-bg-secondary-500 { background-color: var(--clr-secondary-500); }
.t-bg-secondary-600 { background-color: var(--clr-secondary-600); }
.t-bg-secondary-700 { background-color: var(--clr-secondary-700); }
.t-bg-secondary-800 { background-color: var(--clr-secondary-800); }
.t-bg-secondary-900 { background-color: var(--clr-secondary-900); }

/* Accent Background Color */
.t-bg-accent-50 { background-color: var(--clr-accent-50); }
.t-bg-accent-100 { background-color: var(--clr-accent-100); }
.t-bg-accent-200 { background-color: var(--clr-accent-200); }
.t-bg-accent-300 { background-color: var(--clr-accent-300); }
.t-bg-accent-400 { background-color: var(--clr-accent-400); }
.t-bg-accent-500 { background-color: var(--clr-accent-500); }
.t-bg-accent-600 { background-color: var(--clr-accent-600); }
.t-bg-accent-700 { background-color: var(--clr-accent-700); }
.t-bg-accent-800 { background-color: var(--clr-accent-800); }
.t-bg-accent-900 { background-color: var(--clr-accent-900); }

/* Danger Background Color */
.t-bg-danger-50 { background-color: var(--clr-danger-50); }
.t-bg-danger-100 { background-color: var(--clr-danger-100); }
.t-bg-danger-200 { background-color: var(--clr-danger-200); }
.t-bg-danger-300 { background-color: var(--clr-danger-300); }
.t-bg-danger-400 { background-color: var(--clr-danger-400); }
.t-bg-danger-500 { background-color: var(--clr-danger-500); }
.t-bg-danger-600 { background-color: var(--clr-danger-600); }
.t-bg-danger-700 { background-color: var(--clr-danger-700); }
.t-bg-danger-800 { background-color: var(--clr-danger-800); }
.t-bg-danger-900 { background-color: var(--clr-danger-900); }

/* Warning Background Color */
.t-bg-warning-50 { background-color: var(--clr-warning-50); }
.t-bg-warning-100 { background-color: var(--clr-warning-100); }
.t-bg-warning-200 { background-color: var(--clr-warning-200); }
.t-bg-warning-300 { background-color: var(--clr-warning-300); }
.t-bg-warning-400 { background-color: var(--clr-warning-400); }
.t-bg-warning-500 { background-color: var(--clr-warning-500); }
.t-bg-warning-600 { background-color: var(--clr-warning-600); }
.t-bg-warning-700 { background-color: var(--clr-warning-700); }
.t-bg-warning-800 { background-color: var(--clr-warning-800); }
.t-bg-warning-900 { background-color: var(--clr-warning-900); }

/* Success Background Color */
.t-bg-success-50 { background-color: var(--clr-success-50); }
.t-bg-success-100 { background-color: var(--clr-success-100); }
.t-bg-success-200 { background-color: var(--clr-success-200); }
.t-bg-success-300 { background-color: var(--clr-success-300); }
.t-bg-success-400 { background-color: var(--clr-success-400); }
.t-bg-success-500 { background-color: var(--clr-success-500); }
.t-bg-success-600 { background-color: var(--clr-success-600); }
.t-bg-success-700 { background-color: var(--clr-success-700); }
.t-bg-success-800 { background-color: var(--clr-success-800); }
.t-bg-success-900 { background-color: var(--clr-success-900); }

/* Info Background Color */
.t-bg-info-50 { background-color: var(--clr-info-50); }
.t-bg-info-100 { background-color: var(--clr-info-100); }
.t-bg-info-200 { background-color: var(--clr-info-200); }
.t-bg-info-300 { background-color: var(--clr-info-300); }
.t-bg-info-400 { background-color: var(--clr-info-400); }
.t-bg-info-500 { background-color: var(--clr-info-500); }
.t-bg-info-600 { background-color: var(--clr-info-600); }
.t-bg-info-700 { background-color: var(--clr-info-700); }
.t-bg-info-800 { background-color: var(--clr-info-800); }
.t-bg-info-900 { background-color: var(--clr-info-900); }

/* Text Color Utilities */

/* Neutral Text Color */
.t-text-neutral-50 { color: var(--clr-neutral-50); }
.t-text-neutral-100 { color: var(--clr-neutral-100); }
.t-text-neutral-200 { color: var(--clr-neutral-200); }
.t-text-neutral-300 { color: var(--clr-neutral-300); }
.t-text-neutral-400 { color: var(--clr-neutral-400); }
.t-text-neutral-500 { color: var(--clr-neutral-500); }
.t-text-neutral-600 { color: var(--clr-neutral-600); }
.t-text-neutral-700 { color: var(--clr-neutral-700); }
.t-text-neutral-800 { color: var(--clr-neutral-800); }
.t-text-neutral-900 { color: var(--clr-neutral-900); }

/* White Text Color */
.t-text-white-50 { color: var(--clr-white-50); }
.t-text-white-100 { color: var(--clr-white-100); }
.t-text-white-200 { color: var(--clr-white-200); }
.t-text-white-300 { color: var(--clr-white-300); }
.t-text-white-400 { color: var(--clr-white-400); }
.t-text-white-500 { color: var(--clr-white-500); }
.t-text-white-600 { color: var(--clr-white-600); }
.t-text-white-700 { color: var(--clr-white-700); }
.t-text-white-800 { color: var(--clr-white-800); }
.t-text-white-900 { color: var(--clr-white-900); }

/* Black Text Color */
.t-text-black-50 { color: var(--clr-black-50); }
.t-text-black-100 { color: var(--clr-black-100); }
.t-text-black-200 { color: var(--clr-black-200); }
.t-text-black-300 { color: var(--clr-black-300); }
.t-text-black-400 { color: var(--clr-black-400); }
.t-text-black-500 { color: var(--clr-black-500); }
.t-text-black-600 { color: var(--clr-black-600); }
.t-text-black-700 { color: var(--clr-black-700); }
.t-text-black-800 { color: var(--clr-black-800); }
.t-text-black-900 { color: var(--clr-black-900); }

/* Primary Text Color */
.t-text-primary-50 { color: var(--clr-primary-50); }
.t-text-primary-100 { color: var(--clr-primary-100); }
.t-text-primary-200 { color: var(--clr-primary-200); }
.t-text-primary-300 { color: var(--clr-primary-300); }
.t-text-primary-400 { color: var(--clr-primary-400); }
.t-text-primary-500 { color: var(--clr-primary-500); }
.t-text-primary-600 { color: var(--clr-primary-600); }
.t-text-primary-700 { color: var(--clr-primary-700); }
.t-text-primary-800 { color: var(--clr-primary-800); }
.t-text-primary-900 { color: var(--clr-primary-900); }

/* Secondary Text Color */
.t-text-secondary-50 { color: var(--clr-secondary-50); }
.t-text-secondary-100 { color: var(--clr-secondary-100); }
.t-text-secondary-200 { color: var(--clr-secondary-200); }
.t-text-secondary-300 { color: var(--clr-secondary-300); }
.t-text-secondary-400 { color: var(--clr-secondary-400); }
.t-text-secondary-500 { color: var(--clr-secondary-500); }
.t-text-secondary-600 { color: var(--clr-secondary-600); }
.t-text-secondary-700 { color: var(--clr-secondary-700); }
.t-text-secondary-800 { color: var(--clr-secondary-800); }
.t-text-secondary-900 { color: var(--clr-secondary-900); }

/* Accent Text Color */
.t-text-accent-50 { color: var(--clr-accent-50); }
.t-text-accent-100 { color: var(--clr-accent-100); }
.t-text-accent-200 { color: var(--clr-accent-200); }
.t-text-accent-300 { color: var(--clr-accent-300); }
.t-text-accent-400 { color: var(--clr-accent-400); }
.t-text-accent-500 { color: var(--clr-accent-500); }
.t-text-accent-600 { color: var(--clr-accent-600); }
.t-text-accent-700 { color: var(--clr-accent-700); }
.t-text-accent-800 { color: var(--clr-accent-800); }
.t-text-accent-900 { color: var(--clr-accent-900); }

/* Danger Text Color */
.t-text-danger-50 { color: var(--clr-danger-50); }
.t-text-danger-100 { color: var(--clr-danger-100); }
.t-text-danger-200 { color: var(--clr-danger-200); }
.t-text-danger-300 { color: var(--clr-danger-300); }
.t-text-danger-400 { color: var(--clr-danger-400); }
.t-text-danger-500 { color: var(--clr-danger-500); }
.t-text-danger-600 { color: var(--clr-danger-600); }
.t-text-danger-700 { color: var(--clr-danger-700); }
.t-text-danger-800 { color: var(--clr-danger-800); }
.t-text-danger-900 { color: var(--clr-danger-900); }

/* Warning Text Color */
.t-text-warning-50 { color: var(--clr-warning-50); }
.t-text-warning-100 { color: var(--clr-warning-100); }
.t-text-warning-200 { color: var(--clr-warning-200); }
.t-text-warning-300 { color: var(--clr-warning-300); }
.t-text-warning-400 { color: var(--clr-warning-400); }
.t-text-warning-500 { color: var(--clr-warning-500); }
.t-text-warning-600 { color: var(--clr-warning-600); }
.t-text-warning-700 { color: var(--clr-warning-700); }
.t-text-warning-800 { color: var(--clr-warning-800); }
.t-text-warning-900 { color: var(--clr-warning-900); }

/* Success Text Color */
.t-text-success-50 { color: var(--clr-success-50); }
.t-text-success-100 { color: var(--clr-success-100); }
.t-text-success-200 { color: var(--clr-success-200); }
.t-text-success-300 { color: var(--clr-success-300); }
.t-text-success-400 { color: var(--clr-success-400); }
.t-text-success-500 { color: var(--clr-success-500); }
.t-text-success-600 { color: var(--clr-success-600); }
.t-text-success-700 { color: var(--clr-success-700); }
.t-text-success-800 { color: var(--clr-success-800); }
.t-text-success-900 { color: var(--clr-success-900); }

/* Info Text Color */
.t-text-info-50 { color: var(--clr-info-50); }
.t-text-info-100 { color: var(--clr-info-100); }
.t-text-info-200 { color: var(--clr-info-200); }
.t-text-info-300 { color: var(--clr-info-300); }
.t-text-info-400 { color: var(--clr-info-400); }
.t-text-info-500 { color: var(--clr-info-500); }
.t-text-info-600 { color: var(--clr-info-600); }
.t-text-info-700 { color: var(--clr-info-700); }
.t-text-info-800 { color: var(--clr-info-800); }
.t-text-info-900 { color: var(--clr-info-900); }
}

@media (width <= 600px) {
    /* Background Color Utilities */

/* Neutral Background Color */
.m-bg-neutral-50 { background-color: var(--clr-neutral-50); }
.m-bg-neutral-100 { background-color: var(--clr-neutral-100); }
.m-bg-neutral-200 { background-color: var(--clr-neutral-200); }
.m-bg-neutral-300 { background-color: var(--clr-neutral-300); }
.m-bg-neutral-400 { background-color: var(--clr-neutral-400); }
.m-bg-neutral-500 { background-color: var(--clr-neutral-500); }
.m-bg-neutral-600 { background-color: var(--clr-neutral-600); }
.m-bg-neutral-700 { background-color: var(--clr-neutral-700); }
.m-bg-neutral-800 { background-color: var(--clr-neutral-800); }
.m-bg-neutral-900 { background-color: var(--clr-neutral-900); }

/* White Background Color */
.m-bg-white-50 { background-color: var(--clr-white-50); }
.m-bg-white-100 { background-color: var(--clr-white-100); }
.m-bg-white-200 { background-color: var(--clr-white-200); }
.m-bg-white-300 { background-color: var(--clr-white-300); }
.m-bg-white-400 { background-color: var(--clr-white-400); }
.m-bg-white-500 { background-color: var(--clr-white-500); }
.m-bg-white-600 { background-color: var(--clr-white-600); }
.m-bg-white-700 { background-color: var(--clr-white-700); }
.m-bg-white-800 { background-color: var(--clr-white-800); }
.m-bg-white-900 { background-color: var(--clr-white-900); }

/* Black Background Color */
.m-bg-black-50 { background-color: var(--clr-black-50); }
.m-bg-black-100 { background-color: var(--clr-black-100); }
.m-bg-black-200 { background-color: var(--clr-black-200); }
.m-bg-black-300 { background-color: var(--clr-black-300); }
.m-bg-black-400 { background-color: var(--clr-black-400); }
.m-bg-black-500 { background-color: var(--clr-black-500); }
.m-bg-black-600 { background-color: var(--clr-black-600); }
.m-bg-black-700 { background-color: var(--clr-black-700); }
.m-bg-black-800 { background-color: var(--clr-black-800); }
.m-bg-black-900 { background-color: var(--clr-black-900); }

/* Primary Background Color */
.m-bg-primary-50 { background-color: var(--clr-primary-50); }
.m-bg-primary-100 { background-color: var(--clr-primary-100); }
.m-bg-primary-200 { background-color: var(--clr-primary-200); }
.m-bg-primary-300 { background-color: var(--clr-primary-300); }
.m-bg-primary-400 { background-color: var(--clr-primary-400); }
.m-bg-primary-500 { background-color: var(--clr-primary-500); }
.m-bg-primary-600 { background-color: var(--clr-primary-600); }
.m-bg-primary-700 { background-color: var(--clr-primary-700); }
.m-bg-primary-800 { background-color: var(--clr-primary-800); }
.m-bg-primary-900 { background-color: var(--clr-primary-900); }

/* Secondary Background Color */
.m-bg-secondary-50 { background-color: var(--clr-secondary-50); }
.m-bg-secondary-100 { background-color: var(--clr-secondary-100); }
.m-bg-secondary-200 { background-color: var(--clr-secondary-200); }
.m-bg-secondary-300 { background-color: var(--clr-secondary-300); }
.m-bg-secondary-400 { background-color: var(--clr-secondary-400); }
.m-bg-secondary-500 { background-color: var(--clr-secondary-500); }
.m-bg-secondary-600 { background-color: var(--clr-secondary-600); }
.m-bg-secondary-700 { background-color: var(--clr-secondary-700); }
.m-bg-secondary-800 { background-color: var(--clr-secondary-800); }
.m-bg-secondary-900 { background-color: var(--clr-secondary-900); }

/* Accent Background Color */
.m-bg-accent-50 { background-color: var(--clr-accent-50); }
.m-bg-accent-100 { background-color: var(--clr-accent-100); }
.m-bg-accent-200 { background-color: var(--clr-accent-200); }
.m-bg-accent-300 { background-color: var(--clr-accent-300); }
.m-bg-accent-400 { background-color: var(--clr-accent-400); }
.m-bg-accent-500 { background-color: var(--clr-accent-500); }
.m-bg-accent-600 { background-color: var(--clr-accent-600); }
.m-bg-accent-700 { background-color: var(--clr-accent-700); }
.m-bg-accent-800 { background-color: var(--clr-accent-800); }
.m-bg-accent-900 { background-color: var(--clr-accent-900); }

/* Danger Background Color */
.m-bg-danger-50 { background-color: var(--clr-danger-50); }
.m-bg-danger-100 { background-color: var(--clr-danger-100); }
.m-bg-danger-200 { background-color: var(--clr-danger-200); }
.m-bg-danger-300 { background-color: var(--clr-danger-300); }
.m-bg-danger-400 { background-color: var(--clr-danger-400); }
.m-bg-danger-500 { background-color: var(--clr-danger-500); }
.m-bg-danger-600 { background-color: var(--clr-danger-600); }
.m-bg-danger-700 { background-color: var(--clr-danger-700); }
.m-bg-danger-800 { background-color: var(--clr-danger-800); }
.m-bg-danger-900 { background-color: var(--clr-danger-900); }

/* Warning Background Color */
.m-bg-warning-50 { background-color: var(--clr-warning-50); }
.m-bg-warning-100 { background-color: var(--clr-warning-100); }
.m-bg-warning-200 { background-color: var(--clr-warning-200); }
.m-bg-warning-300 { background-color: var(--clr-warning-300); }
.m-bg-warning-400 { background-color: var(--clr-warning-400); }
.m-bg-warning-500 { background-color: var(--clr-warning-500); }
.m-bg-warning-600 { background-color: var(--clr-warning-600); }
.m-bg-warning-700 { background-color: var(--clr-warning-700); }
.m-bg-warning-800 { background-color: var(--clr-warning-800); }
.m-bg-warning-900 { background-color: var(--clr-warning-900); }

/* Success Background Color */
.m-bg-success-50 { background-color: var(--clr-success-50); }
.m-bg-success-100 { background-color: var(--clr-success-100); }
.m-bg-success-200 { background-color: var(--clr-success-200); }
.m-bg-success-300 { background-color: var(--clr-success-300); }
.m-bg-success-400 { background-color: var(--clr-success-400); }
.m-bg-success-500 { background-color: var(--clr-success-500); }
.m-bg-success-600 { background-color: var(--clr-success-600); }
.m-bg-success-700 { background-color: var(--clr-success-700); }
.m-bg-success-800 { background-color: var(--clr-success-800); }
.m-bg-success-900 { background-color: var(--clr-success-900); }

/* Info Background Color */
.m-bg-info-50 { background-color: var(--clr-info-50); }
.m-bg-info-100 { background-color: var(--clr-info-100); }
.m-bg-info-200 { background-color: var(--clr-info-200); }
.m-bg-info-300 { background-color: var(--clr-info-300); }
.m-bg-info-400 { background-color: var(--clr-info-400); }
.m-bg-info-500 { background-color: var(--clr-info-500); }
.m-bg-info-600 { background-color: var(--clr-info-600); }
.m-bg-info-700 { background-color: var(--clr-info-700); }
.m-bg-info-800 { background-color: var(--clr-info-800); }
.m-bg-info-900 { background-color: var(--clr-info-900); }

/* Text Color Utilities */

/* Neutral Text Color */
.m-text-neutral-50 { color: var(--clr-neutral-50); }
.m-text-neutral-100 { color: var(--clr-neutral-100); }
.m-text-neutral-200 { color: var(--clr-neutral-200); }
.m-text-neutral-300 { color: var(--clr-neutral-300); }
.m-text-neutral-400 { color: var(--clr-neutral-400); }
.m-text-neutral-500 { color: var(--clr-neutral-500); }
.m-text-neutral-600 { color: var(--clr-neutral-600); }
.m-text-neutral-700 { color: var(--clr-neutral-700); }
.m-text-neutral-800 { color: var(--clr-neutral-800); }
.m-text-neutral-900 { color: var(--clr-neutral-900); }

/* White Text Color */
.m-text-white-50 { color: var(--clr-white-50); }
.m-text-white-100 { color: var(--clr-white-100); }
.m-text-white-200 { color: var(--clr-white-200); }
.m-text-white-300 { color: var(--clr-white-300); }
.m-text-white-400 { color: var(--clr-white-400); }
.m-text-white-500 { color: var(--clr-white-500); }
.m-text-white-600 { color: var(--clr-white-600); }
.m-text-white-700 { color: var(--clr-white-700); }
.m-text-white-800 { color: var(--clr-white-800); }
.m-text-white-900 { color: var(--clr-white-900); }

/* Black Text Color */
.m-text-black-50 { color: var(--clr-black-50); }
.m-text-black-100 { color: var(--clr-black-100); }
.m-text-black-200 { color: var(--clr-black-200); }
.m-text-black-300 { color: var(--clr-black-300); }
.m-text-black-400 { color: var(--clr-black-400); }
.m-text-black-500 { color: var(--clr-black-500); }
.m-text-black-600 { color: var(--clr-black-600); }
.m-text-black-700 { color: var(--clr-black-700); }
.m-text-black-800 { color: var(--clr-black-800); }
.m-text-black-900 { color: var(--clr-black-900); }

/* Primary Text Color */
.m-text-primary-50 { color: var(--clr-primary-50); }
.m-text-primary-100 { color: var(--clr-primary-100); }
.m-text-primary-200 { color: var(--clr-primary-200); }
.m-text-primary-300 { color: var(--clr-primary-300); }
.m-text-primary-400 { color: var(--clr-primary-400); }
.m-text-primary-500 { color: var(--clr-primary-500); }
.m-text-primary-600 { color: var(--clr-primary-600); }
.m-text-primary-700 { color: var(--clr-primary-700); }
.m-text-primary-800 { color: var(--clr-primary-800); }
.m-text-primary-900 { color: var(--clr-primary-900); }

/* Secondary Text Color */
.m-text-secondary-50 { color: var(--clr-secondary-50); }
.m-text-secondary-100 { color: var(--clr-secondary-100); }
.m-text-secondary-200 { color: var(--clr-secondary-200); }
.m-text-secondary-300 { color: var(--clr-secondary-300); }
.m-text-secondary-400 { color: var(--clr-secondary-400); }
.m-text-secondary-500 { color: var(--clr-secondary-500); }
.m-text-secondary-600 { color: var(--clr-secondary-600); }
.m-text-secondary-700 { color: var(--clr-secondary-700); }
.m-text-secondary-800 { color: var(--clr-secondary-800); }
.m-text-secondary-900 { color: var(--clr-secondary-900); }

/* Accent Text Color */
.m-text-accent-50 { color: var(--clr-accent-50); }
.m-text-accent-100 { color: var(--clr-accent-100); }
.m-text-accent-200 { color: var(--clr-accent-200); }
.m-text-accent-300 { color: var(--clr-accent-300); }
.m-text-accent-400 { color: var(--clr-accent-400); }
.m-text-accent-500 { color: var(--clr-accent-500); }
.m-text-accent-600 { color: var(--clr-accent-600); }
.m-text-accent-700 { color: var(--clr-accent-700); }
.m-text-accent-800 { color: var(--clr-accent-800); }
.m-text-accent-900 { color: var(--clr-accent-900); }

/* Danger Text Color */
.m-text-danger-50 { color: var(--clr-danger-50); }
.m-text-danger-100 { color: var(--clr-danger-100); }
.m-text-danger-200 { color: var(--clr-danger-200); }
.m-text-danger-300 { color: var(--clr-danger-300); }
.m-text-danger-400 { color: var(--clr-danger-400); }
.m-text-danger-500 { color: var(--clr-danger-500); }
.m-text-danger-600 { color: var(--clr-danger-600); }
.m-text-danger-700 { color: var(--clr-danger-700); }
.m-text-danger-800 { color: var(--clr-danger-800); }
.m-text-danger-900 { color: var(--clr-danger-900); }

/* Warning Text Color */
.m-text-warning-50 { color: var(--clr-warning-50); }
.m-text-warning-100 { color: var(--clr-warning-100); }
.m-text-warning-200 { color: var(--clr-warning-200); }
.m-text-warning-300 { color: var(--clr-warning-300); }
.m-text-warning-400 { color: var(--clr-warning-400); }
.m-text-warning-500 { color: var(--clr-warning-500); }
.m-text-warning-600 { color: var(--clr-warning-600); }
.m-text-warning-700 { color: var(--clr-warning-700); }
.m-text-warning-800 { color: var(--clr-warning-800); }
.m-text-warning-900 { color: var(--clr-warning-900); }

/* Success Text Color */
.m-text-success-50 { color: var(--clr-success-50); }
.m-text-success-100 { color: var(--clr-success-100); }
.m-text-success-200 { color: var(--clr-success-200); }
.m-text-success-300 { color: var(--clr-success-300); }
.m-text-success-400 { color: var(--clr-success-400); }
.m-text-success-500 { color: var(--clr-success-500); }
.m-text-success-600 { color: var(--clr-success-600); }
.m-text-success-700 { color: var(--clr-success-700); }
.m-text-success-800 { color: var(--clr-success-800); }
.m-text-success-900 { color: var(--clr-success-900); }

/* Info Text Color */
.m-text-info-50 { color: var(--clr-info-50); }
.m-text-info-100 { color: var(--clr-info-100); }
.m-text-info-200 { color: var(--clr-info-200); }
.m-text-info-300 { color: var(--clr-info-300); }
.m-text-info-400 { color: var(--clr-info-400); }
.m-text-info-500 { color: var(--clr-info-500); }
.m-text-info-600 { color: var(--clr-info-600); }
.m-text-info-700 { color: var(--clr-info-700); }
.m-text-info-800 { color: var(--clr-info-800); }
.m-text-info-900 { color: var(--clr-info-900); }
}